// prettier-ignore
export const FRENCH_TRANSLATIONS = {
  platform: {
    general: {
      downloadCsv: "Télécharger CSV",
      downloadXlsx: "Télécharger XLSX",
      download: "Télécharger",
      downloadFile: "Télécharger le modèle",
      averageTime: "Temps moyen de livraison",
      onTimeDeliveries: "Livraison dans les délais",
      invoicedAmount: "Montant facturé",
      orders: "Nombre de livraison",
      averagePrice: "Prix moyen par livraison",
      averageCost: "Average Cost",
      returns: "Retours",
      return: "Retour",
      serviceLevel: "Option de livraison",
      onTime: "Livraison dans les délais",
      viewInvoice: "Voir la facture",
      settings: "Voir Plus",
      apply: "Appliquer",
      cancel: "Annuler",
      close: "Fermer",
      weekly: "Par semaine",
      monthly: "Par mois",
      total: "Total",
      perCarrier: "Par transporteur",
      perIssue: "Par litige",
      perState: "Par statut",
      loading: "Nous récupérons vos données !",
      downloadCSV:
        "Merci de patienter quelques instants. Nous mettons à jour vos données.",
      asanaForm: "Asana Form",
      noData: "Aucune donnée",
      addFeatures: "Ajouter des fonctionnalités",
      getStarted: "Commencez",
      hi: "Bonjour",
      of: "de",
      currency: "Monnaie",
      currentYear: "Année en cours",
      month: "Mois",
      savings: "Épargne",
      percentage: "Pourcentage",
      cost: "Coût",
      or: "ou",
      unknown: "Inconnu",
      collapseMenu: "Fermer le menu",
      documentationLinkText: "ici",
      unassigned: "Non attribuée",
      carrierPlatform: "Plateforme transporteur",
    },
    files: {
      "generated_invoice": "Facture générée",
      "invoice": "Facture",
      "sales_invoice": "Facture",
      "invoice_file": "Facture",
      "damaged_pictures": "Photos endommagées",
      "damaged_pictures": "Photos endommagées",
      "proof_of_honor": "Preuve d'honneur",
      "proof_of_honour": "Preuve d'honneur",
      "identity_document": "Preuve d'identité",
      "other": "Autre",
      "claim_payment_paperwork": "Papiers de paiement de la réclamation",
      "subscription_file": "Fichier de souscription",
    },
    deliveryIssues: {
      lost: "Perte",
      lateDelivery: "Hors-délai",
      late_delivery: "Hors-délai",
      damaged: "Endommagé",
      deliveryDispute: "Contestation de livraison",
      delivery_dispute: "Contestation de livraison",
      labelNotUsed: "Étiquette non utilisée",
      refundsOnInternationalDeliveries: "Remboursements sur livraison à l'étranger",
      unknown: "Inconnu",
      freight: "Transport",
      tax: "Taxes",
      charges: "Surcharges",
      incident: "Incident",
    },
    onBoarding: {
      title: "Votre compte a été créé ! Configurez-le maintenant.",
      description: "Afin de traiter les informations relatives à vos transporteurs et de commencer à réaliser des économies, nous avons besoin de certaines informations concernant vos transporteurs.",
      team: {
        title: "Donnez un accès aux membres de votre équipe.",
        noResults: "Aucun membre enregistré jusqu'à présent",
      },
      details: {
        title: "Pas d'inquiétude ! Vous ne serez pas facturé avant de recevoir vos premiers remboursements. Cette étape nous permet d'avoir vos informations complètes à jour. Vous aurez la possibilité de les mettre à jour à tout moment dans votre espace dédié sur la plateforme.",
        inputs: {
          legalEntity: "Entité légale",
          country: "Pays",
          city: "Ville",
          address: "Adresse",
          postalCode: "Code postal",
          vat: "Numéro de TVA",
          emails: "Adresse(s) email de réception des factures",
          selectCountry: "Selectionnez le pays",
          typeName: "Entrez le nom",
          typeEmail: "Entrez votre email",
          typeVat: "Entrez votre numéro de TVA",
          typeAddress: "Entrez votre adresse",
          inputCity: "Entrez votre ville",
          logo: "Add Company Logo",
          logoDescription: "Drag and drop image here or click the select file. Add image of maximum size up to 5mb.",
        },
        errorDescriptions: {
          oneImage: "Only one image can be uploaded."
        },
      },
      banking: {
        details: "Afin de pouvoir transférer vos remboursements, merci de renseigner vos informations bancaires.",
        title: "Informations bancaires",
      },
    },
    productTour: {
      home: {
        step_one_title:
          "Bienvenue sur <span style='color: #ff5b6d; font-weight: 500;'>la plateforme Lox</span>,",
        step_one_textBlock:
          "Je m'appelle Isis et je vous accompagnerai lors de vos premiers pas sur la plateforme ! <br /> C'est parti !",
        step_two_textBlock:
          "<p>À partir du menu vous aurez accès à différentes pages.</p><p>Vous aurez notamment accès à différentes analyses de votre logistique.</p>",
        step_three_title: "<p style='color: #ff5b6d'>Attention :</p>",
        step_three_textBlock:
          "<p>Ces deux catégories ne sont pas encore disponibles mais le seront prochainement.</p>",
        step_four_textBlock:
          "<p>Vous rencontrez un bug ou vous avez besoin d'une nouvelle fonctionnalité ? La section feedback est là pour vous !</p> <p>N'hésitez pas à nous laisser tous vos commentaires.</p>",
        step_five_textBlock:
          "<p>En cliquant ici vous pourrez :<p><ul><li>Mettre à jour votre profil</li><li>Gérer vos informations transporteurs</li><li>Gérez votre équipe</li><li>Re-jouer ce tour de la platforme</li><li>Vous déconnecter</li></ul>",
        step_six_title: "Raccourcis :",
        step_six_textBlock: [
          "<p>Ici sont affichés les résultats de",
          "votre audit réalisé sur vos factures.</p><p>Pour plus de détails vous pouvez cliquer sur une des cartes.",
        ],
        step_seven_textBlock:
          "Ces graphiques vous donneront un aperçu complet sur ce que vous payez à vos transporteurs.",
      },
      refund: {
        step_one_title:
          "Bienvenue sur la page des <span style='color: #ff5b6d; font-weight: 500;'>remboursements</span>,",
        step_one_textBlock:
          "<p>C'est ici que vous trouverez l'ensemble des informations sur les remboursements que la solution a identifiés. ",
        step_two_textBlock:
          "Le premier onglet vous donne une vue d'ensemble sur les remboursements identifiés. ",
        step_three_textBlock:
          "Le second vous offre une analyse des remboursements par numéro de suivi.",
        step_four_textBlock:
          "<p>Vous pouvez affiner votre analyse à l'aide des filtres juste ici.",
      },
      credentials: {
        step_one_title: [
          "Bonjour ",
          " et bienvenue sur <span style='color: #ff5b6d; font-weight: 500;'>La Plateforme Lox</span>.",
          "Je suis Isis et je vais vous guider lors de la finalisation votre compte. Afin d'effectuer notre audit nous avons besoin de vos factures transporteurs",
          "Je suis Isis et je vais vous guider dans ce processus. Ici, vous pourrez télécharger vos factures de transporteur pour que Lox les vérifie.",
        ],
        step_two_textBlock:
          "Vous pouvez entrer juste ici vos identifiants pour accéder à votre compte sur la plateforme du transporteur de votre choix.<p></p> Notre logiciel détectera automatiquement les factures disponibles.",
        step_three_textBlock:
          "Vous pouvez également télécharger vos factures ici.<br></br> Nous acceptons tous les formats.",
        step_four_textBlock:
          "Vous retrouverez ici l'historique des documents que vous nous avez transmis.",
        step_five_textBlock:
          "Une fois vos factures et identifiants ajoutés à nous de jouer !",
        engaged: {
          step_one_title: [
            "Bonjour ",
            " et Bienvenue dans votre <span style='color: #ff5b6d; font-weight: 500;'>Accréditations des Transporteurs</span>.",
            "Je suis Isis et je vais vous guider dans ce processus. Nous aurons besoin de vos informations d'identification afin de télécharger vos factures de transporteur ou simplement de les charger <a style='color: #ff5b6d; font-weight: 500;' href='/carrier/invoices'>ICI</a>",
          ],
          step_two_textBlock:
            "Vous aurez ici un aperçu des identifiants du portail de la facture du transporteur que vous avez créé.<p></p>Au Lox, nous pourrons récupérer votre facture du transporteur chaque fois qu'une nouvelle facture sera disponible.",
        },
      },
      members: {
        step_one_title:
          "Merci d'avoir entré vos <span style='color: #ff5b6d; font-weight: 500;'>Informations Transporteurs</span>.",
        step_one_textBlock:
          "Sur cette page vous avez la possibilté d'inviter des membres de votre équipe à utiliser la plateforme Lox. Vous pouvez en ajouter autant que vous voulez !</p>",
        next_process: "Une fois les changemennts effectués appuyez ici !",
        engaged: {
          step_one_title: "Bienvenue dans la vue <span style='color: #ff5b6d; font-weight: 500;'>des membres de votre équipe</span>."
        },
      },
      changePassword: {
        step_one_title: [
          "Bienvenue sur la <span style='color: #ff5b6d; font-weight: 500;'>plateforme Lox</span>.",
          "Vous êtes ici sur <span style='color: #ff5b6d; font-weight: 500;'>Profile Settings</span>.",
        ],
        step_one_textBlock: [
          "<p>.</p><p>Nous vous conseillons de changer votre mot de passe actuel.</p>",
          "<p>D'ici vous pourrez gérer toutes vos informations personnelles.</p>",
        ],
        step_two_textBlock: "Une fois les changemennts effectués appuyez ici !",
      },
    },
    updateTour: {
      home: {
        cardOne: {
          title: "Nouveautés platform 🌟",
          text: "Il y a du nouveau sur notre plateforme ! Découvrez le à l'aide d'un rapide tour.",
        },
        cardTwo: {
          title: "Menu Lateral",
          text: "Tous vos onglets sont à présents disponible dans le menu latéral !",
        },
        cardThree: {
          title: "Mes Factures",
          text: "Découvrez la nouvelle page dédiée à vos factures transporteurs.",
        },
        cardFour: {
          title: "Mes Infos Transporteurs",
          text: "Découvrez la nouvelle interface pour gérer et mettre à jour vos informations transporteurs. Plus d'informations en cliquant ici !",
        },
      },
      carrierInformation: {
        cardOne: {
          title: "Mes informations transporteur",
          text: "Ici gérez tous vos transporteurs en quelques clicks !",
        },
        cardTwo: {
          title: "Ajouter un transporteur",
          text: "Ajouter de nouveaux transporteurs en renseignant vos identifiants our factures.",
        },
        cardThree: {
          title: "Transporteurs intégrés",
          text: "Mettez à jour ou ajouter des informations pour vos transporteurs actuels.",
        },
      },
      myInvoices: {
        cardOne: {
          title: "Mes Factures",
          text: "Dans cette nouvelle page vous trouverez toutes vos factures transporteur prises en charge par la solution.",
        },
        cardTwo: {
          title: "Télécharger Les Factures",
          text: "Vous aurez également la possibilité de télécharger les détails de vos factures en format CSV.",
        },
        cardThree: {
          title: "Montant Remboursé",
          text: "Enfin, pour chaque facture retrouvez le montant précis des remboursements réalisés grâce aux enquêtes de Lox.",
        },
      },
    },
    newUpdates: {
      home: {
        comingSoon: {
          title: "Nouvelle page d'accueil bientôt disponible !",
          text: "Dans quelques jour sera mis en ligne une nouvelle page d'age sur votre plateforme.",
        },
        landed: {
          title: "Une nouvelle page d'accueil a vu le jour !",
          text: "Découvrez le nouveau design de notre page d'accueil et profitez d'une toute nouvelle expérience utilisateur.",
        },
      },
      dataNeeded: {
        comingSoon: {
          title: "ILa page 'Pièces justificatives' sera bientôt disponible.",
          text: "Le processus de téléchargement de masse de vos pièces justificatives se refait une beauté afin de vous proposer une meilleure expérience. Disponible dans les jours à venir !",
        },
        landed: {
          title: "Nouvelle page disponible !",
          text: "Découvrez une nouvelle façon d'utiliser le téléchargement de masse de vos informations colis via CSV ou XLSX.",
        },
      },
    },
    noResults: {
      title: "Aucun résultat à afficher",
      text: "Filtres actuellement appliqués :",
    },
    profile: {
      title: "Paramètres",
      personalTitle: "Informations personnelles",
      passwordTitle: "Changer le mot de passe ",
      newUser: "Inviter un collaborateur",
      sendInvite: "Envoyer une invitation",
      sendInvites: "Envoyer des Invitations",
      forgotPassword: "Mot de passe oublié ?",
      firstName: "Prénom",
      lastName: "Nom",
      company: "Entreprise",
      email: "Email",
      alreadyLogged: "Utilisateur Actif",
      activeUser: "Actif",
      unactiveUser: "Inactif",
      currentPassword: "Mot de passe actuel",
      password: "Nouveau mot de passe",
      passwordVerification: "Confirmer le mot de passe",
      updateProfile: "Mettre à jour mon profil",
      verification: {
        newPassword:
          "Merci d'entrer minimum 8 caractères dont 1 majuscule et 1 chiffre",
        samePassword: "Veillez à ce que les mots de passe soient les mêmes.",
      },
    },
    members: {
      invitations: {
        title: "Invité(s) en attente",
        queued: "En attente",
        processing: "Envoi en cours",
        sent: "Envoyé",
        failed: "L'envoie a échoué",
        invite: "Inviter",
      },
    },
    tasks: {
      title: "Mes tâches",
      subTitle:
        "Téléchargez la liste des livraisons ayant besoin de la facture de vente pour finaliser leurs remboursements puis glissez les factures nécessaires dans la partie de droite!",
      leftPanel: "Nombre de livraisons ayant besoin d'une facture de vente:",
      leftPanelButton: "Télécharger la liste des livraisons",
      righPanel:
        "Importer les factures de vente en PDF, PNG ou JPG - avec le numéro de commande en tant que nom de fichier",
      rightPanelDrop: "Glisser les factures ici.",
    },
    invoices: {
      title: "Mes factures Lox",
      description: "Vous pouvez y consulter, explorer et gérer vos factures.",
      subTitle: "Factures",
      titleInvoice: "Détails de la facture",
      invoicedTo: "Destinataire",
      btw: "TVA",
      invoiceNumber: "Numéro de facture",
      acctNumber: "Numéro de compte",
      vatNumber: "Numéro de TVA",
      invoiceDate: "Date de facturation",
      cocKvK: "SIRET",
      orderDetails: "Détails des remboursements",
      vat: "TVA",
      total: "Total",
      summary: "Récapitulatif",
      popup: "Patientez un instant, votre facture sera prête dans 3 secondes.",
      vatText: "La TVA est auto liquidée.",
      files: "Format acceptés : CSV, Excel, XML files.",
      dropFiles: "Drag and drop ici",
      selection: "Choisir un",
      fileType: "fichier",
      selectFile: "Sélectionner le fichier",
      templateType: "votre document.",
      findFiles: "choisir les fichiers",
      uploaded: "fichiers téléchargés",
      uploading: "Télécharger { total } Documents",
      pleaseWait: "Veuillez patienter, cela peut prendre une minute.",
      noData: "Aucun document ajouté",
    },
    parameters: {
      title: "Paramètres",
      titleExpand: "Paramètres - Cliquez pour agrandir",
      dateFrom: "De",
      dateTo: "À",
      datet: "Date",
      value: "Valeur",
      transitTime: "Temps de transit (jours)",
      onTime: "A l'heure ( % )",
      carriers: "Transporteurs",
      carrier: "Transporteur",
      choice: "Choix",
      countrySender: "Pays expéditeur",
      countryReceiver: "Pays receveur",
      problemsEncountered: "Litiges identifiés",
      typeDelivery: "Type de coûts",
      serviceLevel: "Option de livraison",
      selectAll: "Tout sélectionner",
      clear: "Supprimer",
      run: "Rafraichîr",
    },
    placeholder: {
      trackingNumbers: "Insérer un numéro de suivi",
      invoiceNumbers: "Rechercher un numéro de facture",
      statementNumber: "Tapez un numéro de déclaration",
      references: "Insérer un numéro de commande",
      search: "Rechercher une information",
      issue: "État de la question",
      carrier: "Sélectionner un transporteur",
      refunds: "Sélectionner un litige",
      tracking_number: "000001234",
      nb_of_items: "Par exemple : 5",
      unitPrice: "Par exemple : 120",
      content: "Par exemple : vêtements, piles",
      cost_merchandise: "€25.00",
      email: "Tapez votre e-mail",
      password: "Tapez votre mot de passe",
      firstName: "Votre nom",
      lastName: "Votre nom de famille",
      company: "Nom de la société",
      jobTitle: "Titre du poste",
      phone: "111 555 33 77",
      url: "https://",
      portal_url: "Tapez l'URL de la plateforme du transporteur",
      username: "Tapez votre nom d'utilisateur",
      invoiceName: "Nom apparaisssant sur la facture",
      receptionEmail: "Email d'envoi des factures",
      country: "Selectionner un pays",
      vat: "Numéro de TVA",
      city: "Nom de la ville",
      zip: "e.g. 89381-6757",
      street: "Rue, immeuble, appartement",
      comment: "Merci de nous indiquer si vous avez des spécificités avec ce transporteur",
      searchCarrier: "Rechercher un transporteur...",
      net_amount: "€25.00",
      amount_incl: "€29.95",
      tax_percentage: "21",
      sales_date: "YYYY/MM/DD",
      selectCompanies: "Sélectionner les entreprises",
      emailTypes: "Sélectionner les modes d'envoi des e-mails",
      typeCarrier: "Type Nom du transporteur",
      firstName: "Entrez le prénom des membres",
      lastName: "Entrez le nom de famille des membres",
      email: "Entrez l'email des membres",
      multiSearch: "Recherche par numéro de suivi, transporteur ou numéro de commande",
      downloadFile: "Télécharger le fichier sous :",
      memberEmail: "Entrez l'email des membres",
      quantity: "2",
      startTyping: "Insérez votre message ici",
      filters: {
        carrier: "Filtrer par transporteur",
        issue: "Filtrer par litige",
        state: "Filtrer par statut",
        stage: "Filtrer par phase",
        owner: "Filtrer par propriétaire",
      },
      selectCarrierFirst: "Sélectionnez un transporteur pour afficher les litiges pouvant faire l’objet d’une enquête",
      noInvoices: "Aucune facture ci-jointe",
      noImage: "Aucune image jointe",
      bank_name: "ING Bank NV",
      bank_account_number: "12345678910",
      IBAN: "NL20INGB0001234567",
      BIC: "INGBNL2A",
    },
    csv: {
      carrier: "Transporteur",
      description: "Description",
      amountPaid: "Montant payé",
      postalCode: "Code postal du destinataire",
      countryReceiver: "country_code_receiver",
      countrySender: "country_code_sender",
      trackingNumber: "Numéro de suivi",
      netAmount: "net_amount",
      label: "Étiquette",
      orders: "Commande",
      averagePaid: "Prix moyen",
      serviceLevel: "service_level",
      invoiceDate: "Date de facturation",
      invoiceNumber: "Numéro de facture",
      reference: "Numéro de commande",
      totalPrice: "Montant",
      state: "Statut de la enquête",
      reasonRefund: "Litige",
      creditInvoice: "credit_invoice_number",
      weight: "Poids",
      height: "Hauteur",
      length: "Longueur",
      width: "Largeur",
      accountNumber: "Numéro de compte",
      quantity: "Quantité",
      return: "Retours",
      claimDate: "Ouverture de la enquête",
      owner: "Propriétaire",
      stage: "Stade",
      lastCarrierMessage: "Dernier message du transporteur",
      requestDate: "Ouverture de la enquête",
      claimNumber: "Numéro de réclamation",
      creditedAmount: "Montant crédité",
    },
    filterForm: {
      title: "Filtres",
    },
    arrayColumns: {
      costs: "Coûts",
      cost: "Coût",
      costPercentage: "Coût %",
      returnPercentage: "Retour %",
      amountPaid: "Montant payé",
      paidMoreThan: "Payé plus de:",
      postal: "Code postal",
      description: "Description",
      carriers: "Transporteur",
      orders: "Livraisons",
      order: "Livraison",
      day: "jour",
      lastUpdate: "Dernière mise à jour",
      admin: "Admin",
      serviceLevels: "Option de livraison",
      invoiceDate: "Date de facture",
      claimDate: "Date de l'enquête",
      invoiceNumber: "Numéro de facture",
      creditInvoiceNumber: "Numéro de facture du crédit",
      orderReference: "Référence de livraison",
      reference: "Numéro de commande",
      trackingNumber: "Numéro du suivi",
      customDetail: "Personnalisez",
      totalPrice: "Montant",
      totalCost: "Coût total",
      monthlyTotal: "Total mensuel",
      monthlySavings: "Économies mensuelles",
      valueInTotal: "Valeur dans le total",
      averagePaid: "Moyenne payée",
      totalOrders: "Total des livraison",
      problems: "Litige",
      avgShipment: "Moy. par colis",
      portalUrl: "Lien URL",
      link: "Lien",
      states: "Statut de la rèclamation",
      height: "Hauteur",
      length: "Longueur",
      width: "Largeur",
      countriesSent: "Pays expéditeur",
      countriesReceived: "Pays de réception",
      accountNumbers: "Numéro de compte",
      numberOfPackages: "Quantité",
      returns: "Retours",
      netAmount: "Coût",
      weight: "Poids",
      edit: "Modifier",
      update: "Mettre à jour",
      upload: "Télécharger",
      fileUpload: "Télécharger de nouveaux documents",
      uploadInvoices: "Factures téléchargées ",
      documents: "Documents",
      invoiceUpload: "Ajouter de nouvelles factures",
      addDocument: "Ajouter un document",
      uploadAnInvoice: "Télécharger une facture",
      uploadTracking: "Téléchargez la facture pour ",
      uploadBelow: " ci-dessous",
      uploadFiles: "Derniers documents téléchargés",
      credentials: "Identifiants",
      updateCarrier: "Mettre à jour les identifiants",
      deleteCarrier: "Supprimer identifiants",
      receivedInvoices: "Factures reçues",
      type: "Type",
      delete: "Supprimer",
      new: "Nouveau",
      username: "Identifiant",
      password: "Mot de passe",
      urlPortal: "Lien URL",
      dueDate: "Date d'échéance",
      amount: "Montant",
      savedAmount: "Montant remboursé",
      totalAmount: "Montant total",
      dueAmount: "Montant dû",
      status: "Statut",
      reason: "Raison",
      creditDate: "Date de crédit",
      quantity: "Quantité",
      discount: "Remise",
      fee: "Montant facturé",
      from: "de",
      to: "à",
      shipments: "# Colis",
      lane: "Acheminement",
      ship: "Colis",
      label: "Étiquette",
      freightPaid: "Coût de fret payé",
      surchargesPaid: "Surcharges payées",
      percentage: "Pourcentage",
      all: "Tous",
      top5: "Top 5",
      freight: "fret",
      last30Days: "30 derniers jours",
      last90Days: "90 derniers jours",
      last12Months: "12 derniers mois",
      lastYear: "12 derniers mois",
      totalPercentage: "du total",
      columns: "Ajouter une Colonne",
      uploadedDate: "Date de téléchargement",
      uploadDate: "Date de téléchargement",
      access: "Accès",
      filename: "Nom du fichier",
      name: "Nom",
      claimedBy: "réclamé par",
      bothCompanies: "les deux",
      unit: "unité",
      wrongCredential: "Identifiants actifs",
      active: "Actif",
      notActive: "Inactif",
      notWorking: "Ne fonctionne pas",
      daysLeft: "Jours restants pour réclamer",
      claimableReason: "Litige",
      days: "Jours",
      uploaded: "Téléchargé !",
      actions: "Actions",
      comment: "Laissez un commentaire",
      downloadInvoice: "Télécharger la facture",
      downloadDetails: "Télécharger les détails",
      actionNeeded: "Action requise",
      issue: "Litige",
      statement: "Déclaration",
      statementDetails: "Détails de la déclaration",
      statements: "Déclarations",
      statementNumber: "Numéro de la déclaration",
      statementDate: "Date de la déclaration",
      monthMissing: "Mois manquant",
      uploadPoaDocument: "Télécharger le document POA",
      stage: "Stade",
      action: "Action",
      details: "Details",
      change: "Modifier",
      owner: "Propriétaire",
      exportColumns: "Colonnes présentes dans l'export",
      claimNumber: "Numéro de réclamation",
    },
    spend: {
      overviewTitle: "Vous trouverez sur cette page une vue d'ensemble de vos enquêtes",
    },
    credentials: {
      value: "Identifiants",
      title: "Mes Identifiants Transporteur",
      subTitle: "Identifiants Transporteur",
      subTitleSentence:
        "Vous n'avez pas toutes les informations ? Pas de souci ! Vous pourrez les renseigner ou les modifier plus tard après avoir sauvegardé",
      credentialForm: "Ajouter {carrier} transporteur",
      uploadInvoices: "Télécharger une facture ou un contrat transporteur",
      uploadFiles: "Choisir un fichier",
      confirmFiles: "Télecharger les fichiers",
      dropHere: "Glissez vos factures ici.",
      newCredentials: "Ajouter un autre identifiant",
      otherCarrier: "Autre transporteur",
      fileName: "Nom du fichier",
      invoiceDoc: "Facture",
      contract: "Contrat",
      credit: "Note de crédit",
      other: "Autre",
      progressText: "Fichiers téléversés :",
      waitForProgress:
        "Veuillez patienter pendant que nous téléchargeons tous vos documents.",
      selectCarrier: "Sélectionner les transporteurs",
      addTeam: "Ajouter votre équipe",
      companyDetails: "Détails de l'entreprise",
      additionalInfo: "Informations complémentaires",
      invoice: "Factures du transporteur",
      platformTitle: "Identifiants de plateforme",
      documentTitle: "Document du transporteur",
      requestPerSection: "Sauvegarde de vos informations",
      integrated: "Intégré par Lox",
      comingSoon: "Bientôt disponible",
      main: "Ajouter un nouveau transporteur",
      description: "Sélectionnez votre transporteur et suivez les instructions.",
      myCarriers: "Mes transporteurs",
      noCarriers: "Vous n’avez pas de transporteur connecté pour le moment. Ajoutez en au moins un pour commencer à faire des économies avec Lox",
      addCarrier: "Ajouter un transporteur",
      selectType: "Selectionnez le type",
      loadingDocuments: "Veuillez patienter pendant que nous téléchargeons vos fichiers",
      active: "Activé",
      inactive: "Les identifiants ne fonctionnent pas",
      processed: "Effectué",
      notProcessed: "Non effectué",
      noData: "Aucun identifiant ajouté",
      displayNoData: "Il n'y a actuellement aucune information disponible. Pour ajouter un transporteur et ses informations, il suffit de cliquer sur",
      getStarted: "Et c'est parti !",
      missing: "Manquante",
      glsDeDescription: "Afin d’ajouter GLS DE, merci de renseigner l’adresse mail à utiliser pour envoyer les réclamations. Si vous n'êtes pas en possession de cette adresse mail nous vous invitons à la réclamer auprès de votre gestionnaire de compte chez GLS DE. Cette adresse mail est propre à chaque e-commerce, de ce fait sans cette dernière il nous sera impossible de traiter vos réclamations.",
    },
    refunds: {
      overview: "Aperçu",
      details: "Détails",
      moreDetails: "Plus de détails",
      lostData: "Données perdues",
      myClaims: "Ouvrir une enquête",
      investigationDetails: "Détails des enquêtes",
      billingAndSubscription: "Facturation et abonnement",
      all: "Tous",
      byLox: "Par Lox",
      calendarFilter: "Filtre sur {filter}",
      invoiceDate: "la date de facturation",
      claimDate: "Date de l'enquête",
      removeGood: "Supprimer la marchandise",
      uploadInvestigation: "Upload investigation support document",
      noUpdateAvailable: "Aucune mise à jour disponible",
      status: "Mise à jour",
      betaUpdates: "Mise à jour BETA",
      incidentExplanation: "Cette enquête provient de la plateforme transporteur et le problème de livraison n’a pas pu être identifié. Pour plus de détails merci de consulter l’historique.",
      unknownExplanation: "Le statut de cette réclamation semble être inconnu. Nous vous invitons à mettre à jour cette dernière.",
      investigationHistory: {
        title: "Historique de l'enquête",
        trackingNo: "Nu. de suivi",
        investigationNumber: "Numéro de l'enquête",
        investigationCreatedDate: "Date de création de l'enquête",
        issueType: "Type de litige",
        amountCreditedValueOfInvestigation: "Montant crédité/Valeur de l'enquête",
        attachedDocuments: "Documents joints",
      },
      actionRequiredModal: {
        title: "Télécharger des documents",
        subTitle: "Téléchargez un document de 5 Mb max. Formats acceptés {types} format. ",
        carrier: "Dernier message du transporteur",
        drag: "Télécharger un document, ou",
        click: "cliquez ici pour ajouter un document",
        message: "Tapez votre message ici",
      },
      emptyTable: {
        actionRequired: {
          title: "À jour",
          text: "Aucune investigation ne nécessite une action",
        },
        noInvestigations: {
          title: "Aucune investigation disponible",
          text: "Aucune investigation n’a pû être trouvée. Ouvrez dès maintenant vos investigations !",
        },
        carrierInformation: {
          text: "Aucune action n'est nécessaire pour l'instant en ce qui concerne les lettres de créance.",
        },
        activeFiltersNoResults: {
          title: "Aucune investigation trouvée",
          text: "Aucune investigation ne correspond aux filtres appliqués."
        },
      },
      notifyUserModal: {
        confirmation: {
          title: "Avertissement",
          explanation: "Êtes-vous sûr de vouloir arrêter l'enquête ? Une fois arrêtée il vous sera impossible de la modifier ou l'ouvrir de nouveau.",
          buttonLabel: "Arrêter l'enquête",
        },
        success: {
          title: "Succès",
          explanation: "L'enquête a été clôturée avec succès. Vous pouvez cependant toujours accéder à l'historique de celle-ci.",
          buttonLabel: "Fermer",
        },
        loading: {
          explanation: "Nous fermons votre réclamation, cela peut prendre quelques secondes ...",
        },
      },
      actionRequired: {
        pageBanner: {
          invoice_missing: {
            title: "Informations sur la marchandise",
            text: "Ces enquêtes nécessitent des informations sur les colis."
          },
          documents_missing: {
            title: "Documents",
            text: "Ces enquêtes nécessitent des documents supplémentaires pour être traitées."
          },
          pictures_missing: {
            title: "Photos",
            text: "Ces enquêtes nécessitent des photos supplémentaires pour être traitées."
          },
          unknown: {
            title: "Inconnu",
            text: "Toutes les enquêtes en cours nécessitant une action de l'utilisateur."
          },
          carrier_information: {
            title: "Informations sur le transporteur",
            text: "Certaines informations sur les transporteurs sont manquantes."
          },
        },
        modal: {
          invoiceMissing: {
            title: "Télécharger la facture manquante",
            subTitle: "Télécharger la facture {carrier} pour le mois de {month}",
            acceptedTypes: "Types de fichiers acceptés: {fileTypes}",
          },
          updateCredential: {
            title: "Update Faulty Credential",
            subTitle: "Fix the faulty credential by keeping information up to date."
          },
        },
      },
      bulkUpload: {
        title: "TÉLÉCHARGEMENT DE MASSE",
        subTitle: "Ajoutez le détail de vos envois à l’aide d’un fichier CSV/XLSX.",
        explanation: {
          first: "Besoin d’aide ? Retrouvez une documentation détaillée ",
          second: "Toutes les informations relatives aux erreurs et leurs résolutions peuvent être trouvées juste ",
          third: "Merci de suivre les étapes suivantes afin d'upload votre document.",
          linkOne: "ici",
          linkTwo: "ici",
        },
      },
      singleUpload: {
        title: "Téléchargement individuel",
        subTitle: "Ajoutez le détail de vos envois un à un.",
      },
      
      identifiedIssues: "TOTAL DES NUMÉROS DE SUIVI",
      uploadExplanation: "Déposez votre document afin de débuter le processus de téléchargement de masses.",
      singleExplanation: "Déposez vos détails marchandise pour chacun de vos numéros de suivi.",
      claims: {
        createButton: "Créer une Nouvelle Enquête",
        updateClaim: "Mise à jour des informations relatives à l'enquête",
        tableTitle: "Vous pouvez créer ici votre propre enquête transporteur via la plateforme Lox.",
        popInstructions: "S'il vous plait, sélectionnez un transporteur",
        sendClaim: "Créer une enquête",
        invoiceDescription: "Glissez-déposez le fichier ici ou cliquez sur le bouton Sélectionner un fichier. Ajouter un fichier dans les formats {types} d'une taille maximale de 5 Mo.",
        otherDescription: "Faites glisser et déposez votre facture d'achat ou d'autres documents ici ou cliquez sur le bouton Sélectionner un fichier pour les localiser. Ajoutez des fichiers dans n'importe quel format d'une taille maximale de 5 Mo.",
        newClaim: "Envoyer une enquête",
        addImage: "Ajouter des photos",
        product_invoice: "Ajouter une facture",
        salesInvoice: "Facture de vente",
        addSalesInvoice: "Ajouter une facture de vente",
        addOtherDocuments: "Ajouter des pièces justificatives",
        imageDescription: "Glissez-déposez le fichier ici ou cliquez sur le bouton Sélectionner un fichier. Ajoutez des fichiers au format png ou jpeq, d'une taille maximale de 5 Mo.",
        selectClaim: "Sélectionner une demande",
        details: "Détails de la marchandise",
        documents: "Ajouter des documents",
        submit: "Soumettre",
        uploadFile: "Télécharger l'export",
        matchHeaders: "Vérification des colonnes",
        validateData: "Validation des données",
        submitSection: "Envoi des enquêtes",
        uploadingAnalysis: "Téléchargement",
        claimDetailsDescription: "Téléchargement de tous les détails liés à cette demande, par exemple le transporteur, le problème, etc.",
        documentsDescription: "Téléchargement de tous les documents joints à cette demande.",
        otherDocuments: "Autres documents",
        damagedImages: "Images endommagées",
        noData: "Il n'y a pas encore de revendications à voir ! Vous pouvez commencer à créer des enquêtes",
        stats: {
          all: "Toutes les enquêtes",
          progress: "In progress",
          credited: "Montant indemnisé",
          saved: "Amount Saved",
          declined: "Declined",
          notOpen: "Enquêtes non ouvertes",
          ongoing: "Enquêtes en cours",
          closed: "Enquêtes fermées",
          descriptions: {
            all: "Tous les problèmes de livraison détectés",
            notOpen: "Enquêtes en attente d'ouverture auprès du transporteur",
            ongoing: "Enquêtes en cours auprès du transporteur",
            closed: "Enquêtes fermées auprès du transporteur",
            credited: "Total de montant indemnisé par le transporteur suite aux enquêtes",
          },
        },
        autofillDetails: "Insertion automatique (API/SFTP)",
        autofillHovered: {
          activated: "Grâce à l’insertion automatique activée, nous collecterons le detail de la marchandise du colis. Vous pouvez saisir manuellement les détails de la marchandise en désactivant l'option d’insertion automatique.",
          deactivated: "Aucune intégration des données n’est disponible. Établissez l’intégration pour pouvoir utiliser l’insertion automatique du détail du contenu du colis.",
        },
        noDetails: {
          noData: "Aucune donnée à afficher",
          reason: "Il y a une erreur lors de la récupération de vos données. Veuillez réessayer plus tard."
        },
        investigationCreation: {
          successful: "Création de l’investigation - Ouverte",
          failed: "Création de l’investigation - Échec",
          claimAlreadyExisting: "Création de l’investigation - Investigation déjà ouverte",
        },
        investigationUpdate: {
          successful: "Mise à jour de l’investigation : Succès",
          failed: "Mise à jour de l’investigation : Échec",
        },
        investigationClosed:{
          successful: "Clôture de l’investigation - Succès",
          failed: "Clôture de l’investigation - Échec",
        },
        investigationReminder: {
          successful: "Rappel de l’investigation - Succès",
          failed: "Rappel de l’investigation - Échec",
        },
        clientDataConnection: {
          successful: "Récupération des informations de vente via l'API/SFTP - Succès",
          failed: "Récupération des informations de vente via l'API/SFTP - Échec",
        },
        editInvestigation: {
          successful: "Modification de l’investigation - Succès",
          failed: "Modification de l’investigation - Échec",
          received:"Modification de l’investigation - Reçu",
        },
        document:{
          invoice: "Facture ajoutée à l'enquête",
        },
        message: {
          from: "Message de {carrier}",
          to: "Message pour {carrier}",
          userinput: "Réponse envoyée depuis la plateforme Lox"
        },
        updateInvestigation: "Update Investigation",
      },
      investigations: {
        breadcrumbs: [
          "Upload un document CSV/XLSX",
          "Selection des en-têtes",
          "Ferreurs",
          "Validation du document",
        ],
        unknownDetails: {
          changeState: "Mettre à jour le statut de la réclamation",
          changeStage: "Mettre à jour le stade de la réclamation",
        },
        title: "Instructions",
        subTitle: "Merci de suivre les étapes suivantes  afin d'upload votre document.",
        uploadAreaLabel: "Upload votre document",
        uploadArea: "Vous pouvez déposer votre document ici ou cliquer sur le bouton 'Télécharger'. Ajouter un document en format CSV, XLSX ou XLS.",
        tip: {
          text: "Si vous avez besoin d'informations détaillées sur la fonction, reportez-vous à la rubrique ",
        },
        documentation: {
          text: "Toutes les informations relatives aux erreurs et leurs résolutions peuvent être trouvées juste",
          boldText: "ici",
        },
        notifications: {
          favourite: {
            title: "Investigation ajoutée aux favoris",
            text: "Une investigation a été ajoutée aux favoris. Vous recevrez une notification par mail pour chaque mise à jour. Vous pouvez désactiver les notifications en cliquant de nouveau.",
          },
          unFavourite: {
            title: "Investigation retirée des favoris",
            text: "Cette réclamation ne fait plus partie de vos favoris. Vous ne recevrez plus de notifications par mail pour cette réclamation.",
          },
        },
        steps: [
          "Télécharger le modèle en CSV/XLSX",
          "Remplir le modèle",
          "Upload le document dans l'espace dédié ci-dessous",
          "Vérifier les données",
          "Corriger les possibles erreurs",
          "Valider le document transmis",
        ],
        errors: {
          fileType: "Le fichier ne correspond pas aux formats suivants : XLSX, XLS ou CSV",
          insufficientColumns:{
            update: "The uploaded file does not contain atleast 8 columns.",
            start : "Le fichier téléchargé ne contient pas au moins 4 colonnes.",
          },
          insufficientMatchedColumns: " colonnes ne sont pas sélectionnées",
        },
        columnMatching: {
          title: "Selectionner les en-têtes correspondants",
          subTitle: "Associez les en-têtes de votre document avec ceux présents dans notre modèle. Assurez vous d'associer tous les en-têtes du modèle pour pouvoir accéder à la prochaine étape.",
          selectOption: "Sélectionner",
          selectFormat: "Format de date",
          headers: {
            carrier: "Transporteur",
            reference: "Numéro de commande",
            trackingNumber: "Numéro de suivi",
            salesDate: "Date de vente",
            description: "Description du produit",
            quantity: "Quantité",
            totalPrice: "prix de vente",
            currencyCode: "Devise",
            vat: "Taxe (en pourcentage)",
            check: "Check",
            issue: "Problème de livraison",
          },
          table: {
            headers: {
              user: "En-têtes présents dans votre document",
              lox: "En-têtes présents dans le modèle de Lox",
              sample: "Example de données",
            },
          },
        },
        previousStep: {
          warning: "Les données d'alerte seront perdues",
          text: "Vous serez ramené à la section de correspondance des colonnes.",
          confirmButton: "Confirmer la perte de données",
        },
        validateData: {
          title: "Validation des données",
          subTitleOne: "Nous vérifions que les informations présentes dans votre document soient conformes.",
          subTitleTwo: "Apportez des modifications en cliquant sur les cases ou continuez uniquement avec les enquêtes complètes.",
          showOnlyErrors: "Afficher uniquement les lignes contenant des erreurs",
          showMore: "Afficher plus",
          removedTN: " packages were removed due to unvalidated tracking numbers. Add them via",
          trackingNumbersRemoved: {
            duplicate: "Des doublons semblent être présents dans le fichier. Retrouvez la liste de ces derniers dans cet <span class='link'>export.</span>",
            invalid: "Certains numéros de suivis ont déjà une enquête ouverte. Retrouvez la liste de ces derniers dans cet <span class='link'>export.</span>. Vous pouvez retrouver plus de détails pour ces numéros de suivi dans la page “Détails des enquêtes”",
          },
          noData: {
            title: "Aucun numéro de suivi valide n'a été trouvé dans le fichier téléchargé.",
            text: "Les numéros de suivi transmis ne semblent pas correspondre à ceux présents dans notre modèle à télécharger.\nMerci d’entrer les informations concernants les numéros de suivis identifiés dans l’export CSV/XLSX.",
            solution: "Pour toute addition de numéros de suivi nous vous invitons à créer une nouvelle investigation.",
          },
          hoverMessages: {
            alert: "Erreur",
            error: "Erreur",
            rangeError: "La date indiquée ne rentre pas dans les délais acceptés",
            valueError: "Donnée Invalide",
            carrierError: "Informations d'identification du transporteur invalides",
            formatError: "Format invalide",
            zeroWarning: "Valeur nulle",
            amountWarning: "Montant important",
            emptyCurrency: "Devise par défaut",
            carrierError: "Invalid Carrier Cell",
            NullError: "La cellule semble être vide. Tous les champs doivent être remplis afin d’ouvrir une enquête.",
            InvalidNumberError: "Le contenu de la cellule est invalide. Le format accepté est une valeur numérique. Merci de mettre à jour le contenu de la cellule.",
            AmountConfirmation: "Le montant inséré dans la cellule semble être élevé. Assurez-vous que le montant indiqué est correct.",
            ZeroBasedConfirmation: "Le montant inséré est égal à 0. Cette valeur est accepté mais en dessous du seuil moyen. Merci de vous assurez qu'il n'y a pas d'erreur.",
            NegativeNumberError: "La cellule semble comporter une valeur négative. Celles-ci ne sont pas acceptées. Merci de nous transmettre une valeur positive.",
            ZeroBasedError: "La valeur est égale à 0. Cette valeur n'est pas acceptée dans cette cellule. Merci de la modifier avec un montant supérieur à 0.",
            DecimalError: "La cellule contient un nombre décimal. Dans cette cellule, seules les valeurs entières (1 ou plus) sont acceptées. Merci de modifier le contenu de la cellule avec une valeur entière.",
            DateRangeError: "La date insérée ne rentre pas dans la période acceptée. merci d'entrer une date comprise entre le {0} et le {1}.",
            InvalidDateError: "Le format de la date insérée n'est pas accepté (aaaa-mm-jj). Veuillez mettre à jour le format de votre date.",
            invalidCarrier: "La cellule semble contenir un transporteur inconnu.",
            invalidCurrency: "Il semblerait que la cellule contienne un code devise inconnu. Merci d'entrer un code devise valide dans le menu déroulant.",
            emptyCurrencyDescription: "Il semblerait que la cellule soit vide. Sans insertion d'une devise celle utilisée sera l'euro (€) par défaut. Si l'euro n'est pas la valeur correspondant merci de bien vouloir sélectionner une autre devise.",
            carrierCredentialWarning: "Les identifiants de ce transporteur ne fonctionnent pas. Mettez à jour ces derniers sur votre page info transporteur afin que vos données soient traitées.",
            lengthErrorDescription: "Le numéro de suivi doit comporter au minimum de { length } lettres et/ou chiffres.",
            invalidIssueError: "This cell was found to have a value that isn't a valid issue. Please make sure the value is either Lost, Damaged, Late Delivery or Delivery Dispute.",
            duplicateTrackingNumberError: "Ce numéro de suivi semble avoir des doublons. Merci de sélectionner le problème de livraison pour lequel vous souhaitez ouvrir une enquête.",
            invalidCarrierError: "Le transporteur n’a pas été reconnu. Merci de sélectionner une option valide.",
            invalidCarrierIssue: "The carrier cell was found to have an invalid carrier name. Please update carrier name first, in order to collect the valid list of issues for that carrier."
          },
          overviewBlocks: {
            loading: "Vérification en cours...",
            warning: {
              singular: "avertissement",
              plural: "avertissements",
            },
            error: {
              singular: "erreur",
              plural: "erreurs",
            },
            valid: " lignes ont été validées",
          },
          popMessages: {
            processing: "Processing",
            success: {
              title: "The upload was successful!",
              updationText: "The details have been successfully submitted for the tracking numbers!",
              creationTextAPI: "Your tracking number will be available in the Action Required Page within 24 hours where you'll be able to insert the merchandise info.",
              creationText: "Your tracking number will be available in the Action Required Page within 24 hours where you’ll be able to insert the merchandise info.",
            },
            error: {
              title: "seems like there was an error in submitting the File!",
              text: "Don't worry all your changes have been saved. Please try to submit the file after a short while!",
            },
          },
        },
        submitFile: {
          downloadError: "Télécharger les erreurs recensées",
          proceedWithValid: "Continuer uniquement avec les lignes validées",
          removeFile: "Supprimer le fichier",
          upload: "Sélectionner un fichier",
        },
        details: {
          message: "Message",
          lastUpdated: "Dernière mise à jour",
          uploadDocument: "Télécharger un document",
          noHistory: {
            not_open: "Historique de la réclamation indisponible.",
            error: "Une erreur est survenue lors du chargement de l'historique. Veuillez réessayer ultérieurement.",
          },
        },
      },
      bulkStart: {
        toggles: {
          bulk: {
            title: "Téléchargement de masse de réclamations",
            description: "Ouvrez plusieurs réclamations en une fois à l’aide d’un document CSV/XLXS.",
          },
          single: {
            title: "Ouvrir une investigation",
            description: "Créer une investigation à la fois",
          },
        },
      },
      uploadSelection:
        "Sélectionnez votre méthode de transmission des données de vos produits.",
      API: "Télécharger la documentation API",
      API_description:
        "L'intégration API avec Lox est la solution la plus efficace et durable pour récupérer les données. La mise en place elle est simple et rapide.",
      manual: "Téléchargement Manuel",
      manual_description:
        "Vous pouvez uploader vos informations produits manuellement à l'aide d'un tableau ou en téléchargeant la facture associée.",
      downloadHelp: "Télécharger la documentation API",
      apiHelper: {
        paragraph_1:
          "L'intégration API est la solution la plus efficace et durable pour nous transmettre vos données produit. Elle nécessite que quelques heures pour être installée par votre équipe tech. Nous vous invitons à leur transmettre la documentation API que vous pouvez télécharger ci-dessous.",
        paragraph_2:
          "Une fois l'API mise en place de votre côté vous pouvez cliquer sur le bouton \"Mon API est créée\". Après quoi nous nous nous occupons de finaliser l'intégration.",
      },
      actionHelp: {
        title: "Notre équipe est là pour vous 🌟",
        text: "S'il vous plait, dites nous quels sont vos problèmes?",
        button: "J'ai besoin d'aide"
      },
      actionEdit: {
        button: "Modifier ma configuration API",
      },
      actionApply: "Mon API est créée",
      actionBack: "Retour aux choix",
      actionSetup: {
        title: "Configurer API",
        text: "Veuillez remplir les informations nécessaires ci-dessous pour nous aider à nous connecter à votre API.",
      },
      cancelApi: "Arrêter l'intégration API",
      disconnectApi: "Déconnecter mon API",
      apiStaging: {
        processing: {
          line_1: "Notre équipe finalise votre API.",
          line_2: "Nous reviendrons vers vous sous peu.",
        },
        success: {
          line_1: "Votre API est bien activée.",
          line_2:
            "Accédez aux résultats de cette dernière dans l'onglet \"Détails\".",
        },
      },
      apiConnected: "API connecté",
      apiFailed: {
        title: "Requête a échoué",
        disconnect: "La déconnexion de l'API a échoué. Veuillez réessayer et si le problème persiste, veuillez nous contacter via customersuccess@loxsolution.com",
        connect: "La connexion à l'API a échoué. Veuillez réessayer, si le problème persiste, veuillez faire une capture d'écran de la page et créer un ticket Asana sur la plateforme du site internet."
      },
      manualHelper: {
        firstParagraph: "Dans le tableau, vous trouverez des colis qui peuvent faire l’objet d’une enquête, mais pour ce faire, nous avons besoin de plus d’informations sur ces colis. Cela permettra à Lox d’établir des enquêtes complètes.",
        secondParagraphHeader: "Télécharger une facture par numéro de suivi",
        secondParagraph: "En cliquant sur “Télécharger”, vous accéderez à un formulaire. Tous les champs sont obligatoires, afin de compléter le processus de téléchargement. Les données renseignées seront visibles sur votre page “Remboursements” sous 24 heures.",
        bulkHeader: "Téléchargement de masse",
        bulkParagraph: {
          stepOne: "En cliquant sur “Téléchargement de masse”, vous accéderez à un formulaire.",
          stepTwo: "Vous pourrez y télécharger un tableau, au format csv ou xlsx.",
          stepThree: "Une fois le fichier téléchargé, remplissez les informations pour autant de numéros de suivi que possible, sachant qu’il n’est pas nécessaire de remplir tous les numéros de suivi pour télécharger le document.",
          stepFour: "Une fois les données renseignées, merci de télécharger le fichier sur cet onglet. Les résultats seront visibles sur votre page “Remboursements” sous 24 heures.",
        },
      },
      manualAction: "Télécharger des factures",
      hoverTitle: " numéros de suivi ont besoin d'une facture.",
      missing: "Action requise",
      apiMissing: "Nous n'avons rien trouvé pour ces numéros de suivi. S'il vous plaît, téléchargez-les manuellement.",
      howItWorks: "Comment ça marche",
      bulkUploadTitle: "Chargement de Masse CSV",
      bulkSubList: {
        one: " le tableau détaillé et remplissez les informations.",
        two: "Uploadez le tableau complété ci-dessous.",
        three: "Vous rencontrez des erreurs ?",
        find: "Paramètres des données"
      },
      error: {
        title: "Explication de l'erreurs",
        headers: "En-têtes invalides",
        date: "Format de date incorrect",
        noData: "Pas de données dans le fichier",
        noFile: "Pas de dossier chargé",
        extraTrackingNumbers: "Ajout de numéros de suivi",
        multipleProducts: "Plusieurs produits dans un colis",
        acceptedCharacters: "Caractères acceptés",
        do: "Faire",
        dont: "Ne pas faire",
        description: {
          headers: "Ne pas changer les en têtes des colonnes comme ci-dessous.",
          extraTrackingNumbers: "L'ajout de numéros de suivi en plus de ceux déjà insérés dans la liste ne sera pas pris en compte.",
          multipleProducts: "Si un numéro de suivi contient plusieurs produits n'ajoutez pas les informations de tous les produits dans une seule cellule.",
          acceptedCharacters: "L'insertion de caractères non acceptés dans une colonnes rendra la lecture du document impossible.",
          date: {
            do: "Veillez à ce que le format de la date corresponde au format requis AAAA-MM-JJ.",
            dont: "Ne pas ajouter la date dans des formats différents de celui spécifié",
          },
          noData: {
            do: "Si un modèle de masse est téléchargé au format CSV, XLS ou XLSX, tous les champs de chaque numéro de suivi doivent être remplis.",
            dont: "Des informations incomplètes concernant un numéro de suivi peuvent entraîner des erreurs au cours du processus de téléchargement.",
          },
        },
      },
      correct: {
        headers: "En-têtes valides",
        date: "Format correct de la date",
        noData: "Données correctement téléchargées",
        extraTrackingNumbers: "Ajout de numéro de suivi",
        description: {
          headers: "Assurez vous que les en-têtes de vos colonnes soient les mêmes que celles dans notre modèle xlsx.",
          extraTrackingNumbers: "Assurez vous qu'aucun numéro de suivi n'a été ajouté à la liste en dehors de ceux déjà présents dans le document téléchargé.",
          multipleProducts: "Insérez uniquement un produit par ligne. Si le colis contenait plus d'un produit, dupliquez la ligne pour votre numéro de suivi et insérez les informations des autres produits. (Nom du produit, quantité, montant, etc). Pour un numéro de suvi, il est possible d'avoir un nombre illimité de lignes dupliquées.",
          acceptedCharacters: [
            "Vous trouverez ci-dessous la liste des caractères acceptés par colonne:",
            "Sales Date: Les caractères numériques, / et - sont acceptés",
            "Product Description: tous les caractères sont acceptés",
            "Quantity: Seuls les caractères numériques sont acceptés",
            "Total price: Les caractères numériques, , et . sont acceptés",
            "VAT: Les caractères numériques, , , . et % sont acceptés",
          ],
        },
      },
      filter: "Filtres",
      monthly: "Crédits mensuels",
      Weekly: "Crédits hebdomadaires",
      invoicedAmount: "Montant facturé",
      refundableAmount: "Montant remboursable",
      requestedAmount: "Montant reclamé",
      confirmedAmount: "Montant confirmé",
      creditedAmount: "Montant crédité",
      tableTitle: "Détails des remboursements",
      investigationsBreakdown: "Détails des enquêtes",
      investigationsSplit: "Répartition des enquêtes",
      refundBreakdown: "Ventilation des crédits",
      process: {
        requested: "Ceci correspond au montant que Lox a réclamé, car identifié comme étant incorrectement facturé par les transporteurs.",
        confirmed:
          "Ces remboursements contiennent seulement les crédits demandés par Lox puis confirmés par les transporteurs.",
        credited:
          "Ces remboursements contiennent seulement les crédits demandés par Lox puis crédités par les transporteurs.",
      },
    },
    billing: {
      subscription: "Abonnement en cours",
      totalVolumeAnalysed: "Nombre d’envois analysés",
      information: "Information de facturation",
      tableTitle: "Factures, avoirs et relevés",
      bankAccountDetails: "Bank Account Details",
      labels: {
        companyName: "Nom",
        subscriptionPrice: "Prix",
        paymentFrequency: "Fréquence de facturation",
        bundle: "Envois inclus",
        startDate: "Début de l’abonnement",
        endDate: "Fin de l’abonnement",
      },
      tableHeaders: {
        select: "Selectionner",
        exactInvoiceNumber: "N° de Document",
        invoiceDate: "Date",
        documentType: "Type de Document",
        amount: "Montant",
        dueDate: "Date D’échéance",
        status: "Statut",
        action: "Télécharger",
      },
      tableCells: {
        documentType: {
          creditNote: "Avoirs",
          statement: "Relevé",
          invoice: "Facture",
        },
        status: {
          due: "En cours",
          paid: "Payée",
          overdue: "Facture impayée",
        },
      },
      hoverMessage: [
        "Vous avez atteint 80% de la capacité de votre abonnement. Merci ",
        "de contacter votre conseiller Lox.",
        "",
      ],
      breakdownTitles: {
        carrier: "Envois analysés par transporteur",
        month: "Envois analysés par mois",
      },
      overdueInvoice: "Il semblerait qu’un ou plusieurs paiements dûs n’aient pas été réalisés. Sans régularisation de votre part nous serons dans l’obligation de suspendre la solution.",
    },
    team: {
      inQueue: "Inviter dans la queue",
      queue: "Dans la queue",
      processing: "Processing",
      sent: "Sent",
    },
    custom: {
      title: "Custom",
    },
    homepage: {
      title: "Heureux de vous revoir, ",
      subtitle: "Voici un aperçu de vos livraisons pour les 12 derniers mois jusqu'à aujourd'hui",
      monthlyInvoices: "Factures mensuelles",
      weeklyInvoices: "Factures hebdomadaires",
      carrierBreakdown: "Pourcentage par transporteur",
      plan: "Mettre à niveau votre plan",
    },
    home: {
      blockDescriptions: {
        myClaims: "Appuyer ici pour ouvrir une enquête",
        investigationDetails: "Détails de toutes les enquêtes en cours",
        actionRequired: "Actions requises pour certaines enquêtes",
        billingAndSubscription: "Plus d’information sur votre package",
        bundleUsed: "des envois inclus utilisé",
        dataIntegrationReport: "Rapport de la connexion des données",
        lastCalledAt: "Utilisée pour la dernière fois le",
        successRateOfLastCall: "Données reçue lors de la dernière utilisation",
        averageSuccessRate: "Données reçue au cours des 12 derniers mois",
      },
      tabs: {
        actions: "Récapitulatif des actions prises",
        investigations: "Performance des enquêtes ouvertes",
        dateFilter: "Date Filter",
        viewManualInvestigations: "Accéder toutes les réclamations ouvertes manuellement",
        actionDisplay: {
          numberOfActionsTaken: "Nombre d’actions réalisées",
          numberOfInvestigationsCredited: "Nombre d’enquêtes créditées",
          amountCredited: "Montant indemnisé après action",
        },
        table: {
          type: "Type d’action",
          date: "Date",
          state: "Statut de la réclamation",
          creditedAmount: "Montant indemnisé",
          carrier: "Transporteur",
          issue: "Problème de livraison",
          closingTime: "Moyenne du temps de fermeture",
          avgCredited: "Montant moyen d’indemnisation",
        },
      },
    },
    sidebar: {
      home: "Accueil",
      claimCenter: "Centre de enquête",
      dataNeeded: "Données nécessaires",
      uploadInvestigation: "Pièces justificatives",
      myClaims: "Ouvrir une enquête",
    },
    topbar: {
      profile: "Mon Profil",
      credentials: "Info transporteurs",
      carrierInvoices: "Télécharger les Documents du Transporteur",
      invoices: "Facturation et relevés",
      members: "L'équipe",
      tour: "Tour",
      files: "Mes fichiers",
      logout: "Déconnexion",
      notificationsTitle: "Notifications",
      notificationsSubTitle: "Nouvelles Tâches",
      notificationsTextBefore: "Vous avez",
      notificationsTextAfter:
        "factures clients à importer sur notre plateforme pour bénéficier de tous vos remboursements.",
      satisfaction: "Customer Satisfaction",
      satisfaction_text:
        "<p>Please choose one to log out.</p> <p>Help us better understand how your current session has been. See you soon!</p>",
    },
    month: {
      january: "Janvier",
      february: "Février",
      march: "Mars",
      april: "Avril",
      may: "Mai",
      june: "Juin",
      july: "Juillet",
      august: "Août",
      september: "Septembre",
      october: "Octobre",
      november: "Novembre",
      december: "Décembre",
    },
    database: {
      carrier: "Transporteur",
      description: "Description",
      country_code_receiver: "Pays de réception",
      country_code_sender: "Pays expéditeur",
      postal_code: "Code postal",
      postal_code_receiver: "Code postal Récepteur",
      tracking_number: "Numéro du suivi",
      reference: "Numéro de commande",
      is_return: "Retours",
      net_amount: "prix de vente",
      total_net_amount: "prix de vente",
      service_level_agreement: "Option de livraison",
      orders: "Livraisons",
      avg_paid: "Prix moyen",
      cost_merchandise: "Montant des marchandises  ( Taxe exclue. )",
      nb_of_items: "Quantité",
      unitPrice: "Prix de vente unitaire ( Taxe exclue. )",
      sales_date: "Date de la vente",
      user: "Utilisateur",
      reason_refund: "Litige",
      invoice_url: "Facture téléchargée",
      supporting_document_urls: "Documents justificatifs téléchargés",
      damaged_urls: "Photos téléchargées",
      content: "Description des marchandises",
      lost: "Perdu",
      damaged: "Endommagée",
      lateDelivery: "Retard de livraison",
      labelNotUsed: "Étiquette non utilisée",
      residentialSurcharges: "Surtaxe résidentielle",
      freight: "Fret",
      charges: "Charges",
      tax: "Taxe",
      credited: "Dédommagée",
      confirmed: "Confirmée",
      requested: "Enquête ouverte",
      requestedMyClaims: "Traitement",
      refundable: "En attente d'ouverture",
      declined: "Non approuvée",
      actionNeeded: "Action requise",
      processing: "Traitement",
      overdue: "Hors délais",
      delivered: "Livré",
      not_eligible: "Non éligible",
      investigation_stopped: "Investigation Stopped",
      returned_to_sender: "Retourné à l'expéditeur",
      on_time: "Livré dans les temps",
      stopped: "Arrêtée",
      impossible_to_open: "Ouverture impossible",
      invoice_missing: "Informations manquantes",
      picture_missing: "Photos manquantes",
      documents_missing: "Documents manquants",
      pictures_missing: "Photos manquantes",
      original_invoice_missing: "Facture d'origine manquante",
      ongoing: "En cours",
      closed: "Fermée",
      unknown: "Inconnu",
      not_open: "Non ouverte",
      request_for_label_not_used: "Request for label not used",
      costPercentage: "Pourcentage du total",
      number_of_packages: "Nombre de commandes",
      amount: "Montant total",
      account_number: "Numéro de compte",
      discount: "Remise",
      height: "Hauteur",
      incentive_amount: "Montant de l'incitation",
      invoice_number: "Numéro de facture",
      invoice_date: "Date de la facture",
      lead_tracking_number: "Numéro de suivi des pistes",
      length: "Longueur",
      quantity: "Quantité",
      type_charges: "Type d'accusation",
      type_weight: "Type Poids",
      url: "URL",
      weight: "Poids",
      width: "Largeur",
      zone: "Zone",
      invoice_company_name: "Nom de la facturation",
      vat_number: "Numéro de TVA",
      invoicing_emails: "Adresse mail d’envoi des factures",
      finance_email_address: "Adresse mail d’envoi des factures",
      claim_email_address: "Adresse mail de réclamation",
      country: "Pays",
      city_name: "Ville",
      street_name: "Adresse",
      zip_code: "Code postal/ZIP",
      amount_excl: "Montant total (hors taxe)",
      amount_incl: "Montant total (TTC)",
      state: "Statut de la rèclamation",
      tax_percentage: "Taxe %",
      currency_code: "Devise",
      bank_name: "Nom de la banque",
      bank_account_number: "Numéro de compte",
      IBAN: "IBAN",
      BIC: "BIC",
      late_delivery: "Hors-délai",
      label_not_used: "Étiquette non utilisée",
      delivery_dispute: "Contestation de livraison",
      credit_month: "Date de crédit",
      total_price: "Prix total",
      files: "Documents envoyés",
      message: "Message envoyé",
      files_and_message: "Documents et message envoyés",
      bulk_upload: "Téléchargement de masse",
      single_upload: "Téléchargement individuel",
      product_invoice: "Upload Product Invoice",
    },
  },
  dashboard: {
    stats: {
      spend: {
        title: "Dépenses totales",
        link: "Explorer",
      },
      savings: {
        title: "Économies totales",
        link: "Explorer",
      },
      shipments: {
        title: "Nombre d'expéditions",
        link: "Explorer",
      },
      custom: {
        x_axis: {
          title: "X-Axis Data",
          link: "Explorer",
        },
        y_axis: {
          title: "Y-Axis Data",
          link: "Explorer",
        },
        graph: {
          title: "Visualiser les données",
          link: "Explorer",
        },
        filters: {
          title: "Filtre(s) à appliquer",
          link: "Explorer",
        },
      },
    },
  },
  // Front Page
  menu: {
    home: "Accueil",
    solution: "Solution",
    pricing: "Prix",
    contact: "Contact",
    team: "L'équipe",
    login: "Connexion",
    book: "Réserver une démo",
  },
  landing: {
    nav: {
      dashboard: "Tableau de bord",
      refunds: "Remboursements",
      cases: "Études de cas",
      products: "Produits",
      vacancies: "Postes à pourvoir",
      english: "Anglais",
      french: "Français",
    },
    dashboard: {
      dashboardTitle: [
        "Un Outil",
        "Complet",
        " qui offre une visibilité sur votre logistique",
      ],
      dashboardSub:
        "Retrouvez toutes les informations relatives à vos livraisons sur une seule plateforme",
      carouselTitle: "Principaux transporteurs intégrés",
      controlArea: "Contrôlez toute votre logistique au même endroit",
      noCode: "Commencez dès aujourd'hui sans aucune intégration",
      deliveryCarriers: "Partagez vos identifiants transporteurs",
      dashboardRunning: "Votre tableau de bord est disponible !",
      insightsTitle: "Votre logistique à portée de main",
      clockCardTitle: "De la transparence dans vos performances logistiques",
      clockCardBody:
        "Ayez accès à toutes les données concernant vos surcharges, vos délais de livraison et bien plus encore.",
      activityCardTitle: "Filtrez et comparez facilement",
      activityCardBody:
        "Affinez votre analyse à l'aide des filtres selon les pays, la date et d'autres critères.",
      documentCardTitle: "Une plateforme prête à l'emploi",
      documentCardBody:
        "Pour y accéder, rien de plus simple ! Il vous suffit de nous transmettre vos factures transporteurs.",
      pricingPlanTitle: "Un seul outil pour toute l'équipe",
      pricingPlanSub:
        "Une grille tarifaire qui s'adapte à vos besoins en fonction de votre profil logistique. Il vous suffit de sélectionner le nombre de colis que vous envoyez par an.",
      pricingPlanMobile:
        "Une grille tarifaire qui s'adapte à vos besoins en fonction de votre profil logistique. Prenez rendez-vous avec un membre de l'équipe sales pour avoir plus d'information.",
      rangeDetail: "Colis / mois",
      customDetail: "Personnalisez",
      rangeValue: "Par colis analysé",
      pricingServicesTitle: "Services inclus",
      serviceList: [
        "Accès à vie",
        "Profil logistique clair",
        "Gestion de tous vos transporteurs sur une seule plateforme",
        "Aucune intégration nécessaire",
        "Accès pour plusieurs utilisateurs",
        "Assistance 7 jours sur 7",
      ],
      pricingStart: "Réserver une démo",
      teamTitle: "Tous vos Transporteurs Intégrés",
      teamSub: "Une solution adaptée à tous vos transporteurs",
      newTeamTitle: "L'équipe Lox",
      newTeamSub:
        "Le plus important pour nous c'est de créer un produit que les gens aiment.",
      faqTitle: "Foire Aux Questions",
      exchangeTitle: "Nous contacter",
      exchangeSub: "Nous répondons en moins de 24h !",
      emailPlaceholder: "Votre email",
      messagePlaceholder: "Votre message",
      messageSender: "Envoyer",
    },
    refund: {
      refundTitle: [
        "Payez toujours",
        "le bon prix",
        " auprès de vos transporteurs",
      ],
      refundSub:
        "Commencez à réaliser 1% de marge sur votre e-commerce sans rien changer !",
      carouselTitle: "Les transporteurs intégrés",
      controlAreaTitle: "Ce que notre audit analyse",
      controlAreaSub:
        "Voici quelques exemples de ce que notre algorithme analyse pour chacun de vos colis.",
      controlAreaList: [
        "Problèmes de livraison",
        "Etiquettes de livraison non utilisées",
        "Surcharges incorrectes",
        "Erreurs de facturation",
      ],
      insightsTitle: "Une solution sans intégration",
      trackTitle: "Partagez vos identifiants transporteurs",
      trackBody:
        "Dès que votre facture transporteur est éditée nous l'analysons automatiquement.",
      magicTitle: "We do our magic",
      magicBody:
        "Notre algorithme analyse automatiquement vos factures transporteurs. En se basant sur plus de 50 points d'audit, nous vérifions si vous payez le bon prix à vos transporteurs.",
      signTitle: "Économisez jusqu'à 10%",
      signBody:
        "Sans aucun effort de votre part, nous nous occupons de toute la procédure de remboursement !",
      pricingPlanTitle: "Ne payez que pour les résultats",
      pricingPlanSub:
        "Une grille tarifaire qui s'adapte à vos besoins en fonction de votre profil logistique. Il vous suffit de sélectionner le nombre de colis que vous envoyez par an.",
      rangeDetail: "Colis / mois",
      customDetail: "Personnalisez",
      rangeValue: "sur chaque montant remboursé",
      pricingServicesTitle: "Services inclus",
      serviceList: [
        "Accès à vie",
        "Gestion de tous vos transporteurs sur une seule plateforme",
        "Aucune intégration nécessaire",
        "Accès pour plusieurs utilisateurs",
        "Payez uniquement pour les résultats",
        "Assistance 7 jours sur 7",
      ],
      pricingStart: "Réserver une démo",
      pricingMobile: "Commencer",
    },
    cases: {
      subText:
        "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life… or keep paying up to 7x more to find new ones.",
      mainStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
      firstStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
      secondStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
      thirdStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
    },
  },
  homepage: {
    mainBanner: {
      title: "Payez le bon prix pour vos factures de livraison",
      text: "Nous analysons automatiquement les factures de vos différents transporteurs et nous nous occupons du processus de remboursement.",
      cta: "Commencer maintenant",
    },
    team: {
      title: "L'équipe Lox",
    },
    faq: {
      title: "Foire aux questions",
      carrier: {
        title: "Pouvez-vous auditer tous les transporteurs ?",
        text: "Nous auditons les principaux transporteurs. Cependant si vous travaillez avec un transporteur différent, nous pouvons l'intégrer à notre algorithme en quelques heures.",
      },
      invoice: {
        title: "Où est-ce que je peux trouver mes factures ?",
        text: "Cela dépend du transporteur. Vous pouvez les trouver sur votre plateforme transporteur ou les demander directement à votre commercial. Vous pouvez aussi nous contacter, nous sommes là pour ça !",
      },
      trial: {
        title: "Puis-je essayer la solution avant de m'engager ?",
        text: "Bien sûr ! Nous vous proposons un audit de vos factures gratuit et sans engagement sur un mois. Découvrez par vous-même où se trouvent vos erreurs.",
      },
      upload: {
        title: "Comment partager mes factures ?",
        text: "Le plus simple est de nous partager les identifiants de vos plateformes transporteurs. Vous pouvez aussi télécharger vos factures sous format csv, xml ou xlsx directement dans votre plateforme Lox.",
      },
      refund: {
        title:
          "Est-ce que Lox contacte les transporteurs pour les remboursements",
        text: "Oui bien sûr ! Pas d'inquiétude à ce sujet, ne perdez plus de temps à contacter vos transporteurs, nous nous occupons de tout !",
      },
      validation: {
        title: "Comment savoir que j'ai bien reçu m",
        text: "Oui bien sûr ! Pas d'inquiétude à ce sujet, ne perdez plus de temps à contacter vos transporteurs, nous nous occupons de tout !",
      },
    },
    solutions: {
      invoice: {
        title: "Partagez vos factures de livraison",
        text: "Nous récupérons vos factures depuis vos plateformes transporteurs.",
      },
      magic: {
        title: "We do our magic",
        text: "Nos algorithmes auditent automatiquement vos factures transporteurs.",
      },
      save: {
        title: "Économisez jusqu'à 10%",
        text: "Sans aucun effort ni investissement: nous couvrons toute la procédure de remboursement !",
      },
    },
    banners: {
      title: "La solution",
      refund: {
        title: "Repérer les erreurs",
        text: "En se basant sur plus de 50 points d'audit, nous vérifions si vous payez le juste prix à vos transporteurs. Pensez aux colis perdus, endommagés, ou simplement incorrectement facturés.",
      },
      issues: {
        title: "Vos processus de remboursement pris en charge",
        text: "C'est le coeur de notre produit : nous assurons l'intégralité du processus de remboursement. \nDe l'identification, à la déclaration des incidents, nous veillons à ce que vous soyez bien indemnisé.",
      },
      shipping: {
        title: "Votre profil logistique transparent",
        text: "L'analyse de vos factures de livraison permet de définir votre profil d'expéditeur. \nGrâce à des tableaux de bord intuitifs, Lox vous apporte les clés de lecture pour comprendre les enjeux de votre réseau logistique. \nVisualisez les performances de vos transporteurs grâce à une vue d'ensemble claire et concise.",
      },
    },
    pricing: {
      title: "Ne payez que pour les résultats!",
      oneMonthTrial: "Accès d'un mois",
      premium: "Premium",
      free: "Free",
      monthly: "Mensuel",
      popular: "Le plus populaire",
      getStarted: "Commencer maintenant",
      subvalue: "Sur vos remboursements.",
      usp: {
        oneMonth: "Accès d'un mois",
        invoice: "50 points d'audit",
        singleUser: "Accès pour un seul utilisateur",
        successFee: "Payez uniquement pour les résultats",
        shipping: "Profil logistique transparent",
        multiCarrier: "Intégration multi transporteurs",
        multiUser: "Accès pour plusieurs utilisateurs",
        support: "Assistance téléphonique 7/7jours",
        lifeTime: "Accès à vie",
        cancel: "Sans engagement",
        refund: "Remboursement pris en charge",
      },
    },
    testimonials: {
      title: "Ils nous font confiance",
      testimonial1: {
        description:
          "Lox couvre l'ensemble de nos remboursements pour nos livraisons défectueuses. Nous sommes passés d'une vérification manuelle à un processus automatisé, le tout en peu de temps. De plus leur plateforme nous permet d'avoir une vision globale sur nos expéditions et notre stratégie logistique du long terme.",
        company: "DAF ,  Bax",
        origin: "en",
      },
      testimonial2: {
        description:
          "À Helloprint, nous avons réalisé que les factures transporteur nous étaient incorrectement facturées depuis plusieurs années. Lox a pris en charge l'ensemble du processus de remboursement et nous a permis de récupérer 100,000€ sur l'année 2020.",
        company: "DAF ,  Helloprint",
        origin: "en",
      },
      testimonial3: {
        description:
          "Lox nous aide à vérifier chaque mois si nous payons le juste prix pour nos factures d'expéditions. Grâce à leur outil, nous pouvons identifier, réclamer et récupérer les montants qui nous sont incorrectement facturés.",
        company: "Manager Service Client ,  Tuincentrum",
        origin: "en",
      },
      testimonial4: {
        description:
          "J'ai découvert Lox en 2020, cet outil nous a permis d'économiser 9% sur nos factures de transports. La solution est facile à mettre en place, elle nous permet d'automatiser les enquêtes sur les erreurs de livraison: Colis défectueux, Colis perdus, Surcharge colis domicile privé, Étiquettes non utilisées. Lox c'est la solution qui permet de gagner du temps et de l'argent. ",
        company: "Associé Opération Logistique ,  Adesa",
        origin: "fr",
      },
      testimonial5: {
        description:
          "En s'occupant de nos enquêtes, Lox nous a permis d'économiser des milliers d'euros sur nos coûts de livraison ! De plus, grâce à leur plateforme d'analyse de données nous avons un suivi clair de nos dépenses logistiques et de comment réduire nos coûts.",
        company: "Manager de projet ,  Japhy",
        origin: "fr",
      },
    },
    contact: {
      title: "Nous contacter",
      text: "Nous répondons en moins de 24h !",
      button: "Envoyer",
      successMessage: "Votre message à bien été envoyé",
      successTitle: "Email envoyé!",
      email: "Votre Email",
    },
  },
  forgot: {
    title: "Des difficultés à vous connecter ?",
    subTitle: "Veuillez saisir l'adresse email reliée à votre compte. Nous vous enverrons les instructions pour réinitialiser votre mot de passe.",
    remeber: "Vous vous en souvenez ?",
    login: "Connexion",
  },
  reset: {
    title: "Réinitialiser le mot de passe",
    description: "Entrez votre nouveau mot de passe pour réinitialiser les identifiants de votre compte. Attention, ce mot de passe sera utilisé une fois l’action validée.",
  },
  register: {
    title: "Créer un compte",
    subTitle: "Bienvenue à Lox !",
    haveAccount: "Vous avez déjà un compte ?",
    link: "Se connecter",
    button: "Créer un compte",
    normalSignUp: "Créez votre compte Lox",
    googleSignUp: "Continuer avec Google",
    verification: {
      resend: "Renvoyer le code de vérification",
      description: "Vérifiez vos emails, nous vous avons renvoyé un code",
      sent: "Nous vous avons envoyé un email contenant un code de vérification",
      inputCode: "Renseignez votre code ici",
      noResult: "Vous n'avez pas reçu de code ?",
    },
    thanks: " Merci d'avoir créé un compte !",
  },
  login: {
    title: "Bienvenue",
    subTitle: "Entrez vos identifiants pour accéder à la plateforme.",
    noAccount: "Vous n'avez pas encore de compte ?",
    google: "Se connecter avec Google",
    link: "Commencez dès maintenant",
    button: "Se connecter",
    signUp: "S'inscrire",
  },
  inputs: {
    email: "Email",
    newEmail: "Entrez une nouvelle adresse e-mail",
    firstName: "Prénom",
    lastName: "Nom",
    memberEmail: "Email du membre",
    memberFirstName: "Prénom du membre",
    memberLastName: "Nom du membre",
    company: "Entreprise",
    companyName: "Nom de l'entreprise",
    recipientEmail: "E-mails des destinataires",
    jobTitle: "Poste",
    password: "Mot de passe",
    premission: ["J'accepte les ", "Conditions générales d'utilisation"],
    forgotPassword: "Mot de passe oublié ?",
    formatError: "le format n'est pas valide.",
    requiredError: "est requis.",
    NbCharacters: "Au moins {nb} caractères",
    NbUppercase: "Au moins {nb} majuscule",
    NbLowercase: "Au moins {nb} minuscule",
    NbDigit: "Au moins {nb} chiffre",
    rememberMe: "Se souvenir de moi",
    matchError: "Please make sure that both passwords match before moving on.",
    phone: "Numéro de téléphone",
    requireCarrier: "Merci de sélectionner un transporteur.",
    requireUser:
      "Merci d'entrer un l'identifiant assoicié à votre compte transporteur.",
    requirePassword:
      "Merci d'entrer le mot de passe associé à votre compte transporteur.",
    comment: "Vos commentaires",
    document: "Type du document",
    totalFiles: "Nombre total de documents",
    formats: "Veuillez ajouter les documents dans les formats suivants",
    passwordRequirements: {
      length: "Doit contenir au moins 8 caractères",
      capital: "Doit contenir une majuscule",
      lower: "Doit contenir une minuscule",
      digit: "Doit contenir un chiffre",
    },
  },
  carrierInfo: {
    ups: {
      credential: "Afin d'intégrer UPS, nous avons seulement besoin d'accéder à votre plateforme de transporteur. Ces identifiants doivent nous donner accès à la plateforme de facturation et à Quantium si vous utilisez UPS Express.",
      document: "",
    },
    chronopost: {
      credential: "Afin d'intégrer Chronopost, nous aurons besoin de vos identifiants de la plateforme dont vous trouverez le lien ci-dessous. Nous aurons également besoin de vos factures des 12 derniers mois au format CSV. Pour les factures à venir, nous ferons une demande à Chronopost pour être dans la boucle de réception.",
      document: "Afin d'intégrer Chronopost, nous aurons besoin de vos factures des 12 derniers mois au format XLS/XLSX.",
    },
    colissimo: {
      credential: "Afin d'intégrer Colissimo, nous aurons seulement besoin d'avoir accès à vos identifiants de transporteur sur le lien de la plateforme que vous trouverez ci-dessous. Si vous avez plusieurs identifiants, merci de tous les renseigner. Assurez-vous de nous renseigner l'identifiant où nous pourrons trouver les factures.",
      document: "Afin d'auditer Colissimo, nous aurons besoin des factures des 12 mois précédents au format CSV.",
    },
    colisPrive: {
      credential: "Pour pouvoir intégrer Colis Privé nous aurons besoin des accès à la plateforme ci-dessous. Nous aurons également besoin de vos factures transporteurs des 12 derniers mois en format CSV.",
      document: "Merci de télécharger ici vos factures des 12 derniers mois au format CSV.",
    },
    default: {
      credential: "Afin de mieux comprendre votre transporteur, veuillez s'il vous plaît nous fournir toutes les informations nécessaires pour faciliter le processus.",
      document: "Nous vous invitons à ajouter vos factures transporteur ici. Le format idéal est CSV mais tous les formats sont acceptés.",
    },
  },
  cta: {
    login: "Se connecter",
    register: "Créez un compte",
    signup: "Commencez",
    now: "maintenant",
    forgotPassword: "Envoyer un mail",
    remember: "Remember It ?",
    nextProcess: "Suite",
    next: "Suivant",
    magic: "Start the Magic",
    back: "Retour",
    saveAsDraft: "Enregistrer comme brouillon",
    reSend: "Renvoyer",
    done: "Terminé",
    readMore: "Lire plus",
    addCompany: "Enregistrer une nouvelle entreprise",
    save: "Sauvegarder",
    saveStatus: "Sauvegarder le statut",
    split: "Répartition",
    addCase: "Nouvelle analyse",
    add: "Ajouter",
    admin: "Panneau administrateur",
    revert: "rétablir",
    send: "Envoyer",
    pay: "Payer",
    pdf: "PDF",
    csv: "CSV",
    view: "Voir",
    pdfStorage: "Stockage en PDF",
    saveMember: "Sauvegarder le membre",
    validate: "Valider",
    bulkUpload: "Chargement de Masse",
    sendFiles: "Envoyer le document",
    continue: "Continuer",
    connect: "Connecter",
    disconnect: "Déconnecter",
    issue: "émission du rapport",
    other: "Autre",
    claiming: "Enquête",
    runs: "Exécutez",
    yes: "Oui",
    no: "Non",
    sendMail: "Send Mail",
    deleteCredential: "Delete",
    verify: "Vérifier",
    sendCode: "Envoyer le code",
    createAccount: "Créer un compte",
    saveCarrier: "Sauver le transporteur",
    finish: "Terminer",
    addBilling: "Ajouter des informations de facturation",
    sendCode: "Envoyer le code de vérification",
    resetPassword: "Réinitialiser le mot de passe",
    resendCode: "Renvoyez le code",
    testSendOut: "Test d'envoi d'email",
    triggerSendOut: "Déclencher l'envoi d'un courriel",
    addCompanyEmail: "Ajouter une entreprise à la liste des destinataires",
    deleteRecipient: "Supprimer le destinataire",
    deactivateClient: "Désactiver le client",
    activateClient: "Activer le client",
    backToEmails: "Retour à la sélection d'emails",
    updateCompanyEmail: "Mise à jour de la liste d'envoi de l'entreprise",
    dataStored: "Afficher les données stockées",
    reasonsToClaim: "Raisons de réclamer",
    adjustActivity: "Ajuster l'activité",
    sendCode: "Envoyer le code",
    resetPassword: "Réinitialiser le mot de passe",
    allCarriers: "Tous les transporteurs",
    allIssues: "Toutes les litiges",
    redirectToLogin: "Rediriger vers la connexion",
    redirectToHome: "Rediriger vers l'accueil",
    tourUpdates: "Mise à jour de la tournée",
    letsGo: "Commencer le tour",
    skipTour: "Passer le tour",
    seeNow: "Découvrir",
    end: "Fin",
    clearAll: "Tout effacer",
    addMoreGoods: "Ajouter d'autres produits",
    dataNeededPage: "Aller à la page de l'action requise",
    investigationDetailsPage: "Accéder aux détails de l'enquête",
    previous: "Previous",
    export: "Exporter",
    sending: "Envoi en cours...",
    betaUpdates: "Accéder aux dernières mise à jour BETA !",
    betaHistory: "Mise à jour BETA",
    beta: "Bêta",
    notification: "Notification",
    showMore: "Voir plus",
    showLess: "Voir moins",
    changePlan: "Changer mon abonnement",
    viewBreakdown: "Voir détails",
    sendReply: "Répondre au transporteur",
    stop: "Stop",
    addFavourite: "Ajouter aux favoris",
    removeFilters: "Supprimer tous les filtres",
  },
  pageNotFound: {
    title: "Page non trouvée",
    text: "Nous n'arrivons pas à trouver la page que vous recherchez. Soyez redirigé vers la sécurité avec le lien ci-dessous.",
  },
  unauthorized: {
    title: "Accès non autorisé",
    text: "Oh, il semblerait que vous n'ayez pas accès à cette page.Cliquez sur le bouton ci dessous pour retourner sur la page d'accueil."
  },
  terms: {
    tAndCs: "Conditions générales d'utilisation",
    data: "Politique des données",
  },
  message: {
    default: {
      success: {
        title: "Success",
        text: "This is default text please replace me",
      },
    },
    generateInvoice: {
      title: "Il semblerait que la facture de vente n’ai pas été ajouté à cette réclamation. De ce fait nous allons regénérer une nouvelle facture.",
      text: "Si une facture a été ajoutée, merci de vous assurer que cette dernière a été téléchargée au bon endroit ou que le bon choix de document a été fait dans la section “Autres”. Dans le cas échéant nous risquons de transmettre deux factures au transporteur ce qui pourrait porter préjudice à la conclusion de la réclamation.",
      confirmButton: "Générer une facture",
      cancelButton: "Télécharger la facture",
    },
    oneInvoice: {
      title: "Plus d'une facture ?",
      text: "Une seule facture peut être téléchargée par demande. Cependant, il existe une autre zone de dépôt de fichiers intitulée Ajouter documents justificatifs, dans laquelle vous pouvez télécharger plusieurs fichiers. \n\nSi vous souhaitez remplacer le fichier actuel par ce fichier, veuillez confirmer ci-dessous.",
      confirm: "Remplacer le fichier",
      cancel: "Garder l'original",
    },
    unsavedChanges: {
      title: "Il semblerait que vous n'ayez pas sauvegardé des informations.",
      text: "Des données ont été insérées sans être sauvegardées. En quittant cette page vos données seront perdues. ",
      confirm: "Continuer les modifications",
      cancel: "Annuler les modifications",
    },
    billingInformation: {
      title: "Changements sauvegardés",
      text: "Vos informations de facturation ont bien été mises à jour.",
    },
    mailSent: {
      title: "Email envoyé",
      text: "Trouvez vôtre email et suivez les instructions pour changer votre mot de passe.",
    },
    noInformation: {
      title: "Ah !",
      text: "Merci de remplir tous les champs.",
    },
    incorrectHeaders: {
      title: "Ah !",
      text: "Il semble que vous ayez téléchargé un fichier dont les noms d'en-tête de colonne sont incorrects. Veuillez retélécharger le modèle pour vérifier les en-têtes corrects par rapport aux vôtres.",
    },
    registerComplete: {
      info: [
        "Merci pour votre inscription !",
        "Un mail avec un code de vérification vient de vous être envoyé.",
        "Merci de renseigner le code ci-dessous.",
        "succès",
      ],
      cancel: "Je n'ai pas reçu de code",
      confirm: "Code reçu",
    },
    trackingNumberExists: {
      dataNeeded: "Il semble qu'une enquête avec ce numéro de suivi existe déjà dans la page Action requise. Vous pouvez télécharger les factures à cet endroit.",
      investigationDetails: "Il semblerait qu'une enquête ait déjà été ouverte pour ce numéro de suivi. Retrouvez les détails de l'enquête ici.",
      validating: "Validation du numéro de suivi...",
    },
    significantAmount: {
      total: "Warning: Total price is significantly high. Make sure all values are entered correctly before proceeding.",
      tax: "Warning: Exceeds above standard value",
    },
    changeCompany: {
      title: "Changer de compte",
      text: "Sélectionnez un compte ci-dessous.",
      error: {
        title: "Erreur d'accès",
        text: "Ce sous compte ne semble pas être disponible. Merci de renseigner un des comptes associés.",
      },
    },
    requiredFields: {
      general: "Ce champ est obligatoire.",
      description: "Veuillez ajouter la description des marchandises.",
      number: "Veuillez ajouter le nombre de marchandises.",
      unit: "Veuillez ajouter le prix unitaire de chaque produit.",
      tax: "Veuillez entrer le pourcentage des taxes."
    },
    validation: {
      zip: "Merci d'entrer un code postal valide.",
      email: "Veuillez saisir une adresse électronique valide.",
      url: "Veuillez saisir une adresse URL valide.",
    },
    noCodeReceived: {
      title: "Notre équipe technique a été informée",
      text: "Nos techniciens travaillent actuellement pour résoudre ce problème",
    },
    passwordError: [
      "Ah!",
      "Il semblerait qu'une erreur soit survenue. Merci de rééssayer ultérieurement.",
    ],
    userBlocked: [
      "Nombre de tentatives dépassées",
      "Vous avez utilisé toutes vos tentatives pour accéder à votre compte. Nous avons donc restreint votre accès. Pour débloquer l'accès à votre compte merci de contacter notre équipe via customersuccess@loxsolution.com",
    ],
    contactLox: [
      "Vous avez une question ?",
      "Envoyez nous un message, notre équipe vous répondra dans les plus brefs délais.",
      "Tapez votre message ...",
    ],
    passwordUpdated: [
      "Mot de passe mis à jour !",
      "Votre mot de passe a bien été mis à jour !",
      "Nous vous avons envoyé un mail avec les étapes à suivre.",
    ],
    accessGranted: "Les identifiants ont bien été mis à jour !",
    registrationFailure: {
      title: "La crétaion de compte n'a pas aboutie",
      text: "La réation de compte n'a pas pu se faire. Merci d'essayer ultérieurement. Si le problème persiste nous vous invitons à contacter notre équipe."
    },
    loginFailure: {
      title: "La connexion n'a pas aboutie",
      text: "La connexion à votre compte n'a pas pu se faire. Merci d'essayer ultérieurement. Si le problème persiste nous vous invitons à contacter notre équipe.",
      confirmButton: "Contacter otre équipe",
    },
    passwordAuthenticationNotEnabled: {
      title: "Il semblerait que vos identifiants n'existent pas.",
      text: "Merci de contacter notre équipe support via support@loxsolution.com.",
    },
    registerError: [
      "Ah !",
      "Merci de remplir tous les champs obligatoires.",
      "erreur",
    ],
    noDataRetrieved: {
      title: "Oups ! Aucune donnée disponible",
      text: "Nous sommes désolés, mais il semble qu'il y ait eu un problème pour récupérer les informations que vous avez demandées. Si le problème persiste, veuillez contacter notre équipe d'assistance pour obtenir de l'aide. Nous vous remercions de votre compréhension et nous nous excusons pour la gêne occasionnée."
    },
    invoiceUploaded: [
      "Terminé !",
      "Vos document(s) ont bien été téléchargés !",
      "succès",
    ],
    credentialsUploaded: [
      "Téléchargement terminé !",
      "Vos identifiants ont bien été ajoutés !",
    ],
    credentialsUpdated: [
      "Mis à jour !",
      "Vos identifiants ont bien été mis à jour !",
    ],
    credentialsDeleted: [
      "Supprimé !",
      "Vos identifiants ont bien été supprimés.",
    ],
    confirmDelete: [
      "Êtes-vous sûr de vouloir supprimer ces informations ?",
      "Cette action est irréversible.",
      "Oui, supprimer les identifiants.",
    ],
    memberDeleted: ["L'utilisateur a bien été supprimé."],
    notAdded: {
      title: " n'a pas pu être ajouté !",
      text: " est déjà utilisé !",
    },
    // Messages for confirming high amount inputs
    confirmAmount: {
      title: "Êtes-vous sûr ?",
      text: "Vous avez indiqué la valeur {amount} dans le champ taxe/ montant. Pouvez-vous nous confirmer que le montant est correct?",
      confirmButton: "La valeur est correcte",
      cancelButton: "La valeur est incorrecte",
    },
    // Message for when the inclusive amount is less than the exclusive amount
    wrongInclusiveAmount: {
      title: "Informations incorrectes",
      text: "Le montant inclus ne peut être inférieur au montant exclu. Veuillez vérifier ces montants.",
    },
    clientAccess: {
      title: "Pas d'accès administrateur !",
      text: "Veuillez noter que la modification, la suppression et l'ajout d'informations d'identification ne peuvent être effectués que par le client.",
    },
    genericError: [
      "Ah !",
      "Un erreur est survenue !",
      "Email wasn't found unfortunately, you have",
      " tentatives.",
    ],
    teamMemberAdded: [
      "Une invitation a été envoyée",
      " n'a pas pu etre ajouté",
      " identifiant déjà utilisé",
    ],
    uploadError: [
      "Oupss !",
      "Il semblerait qu'un problème soit survenu lors du téléchargement de votre fichier. Veuillez réessayer ultérieurement. Si le problème persiste vous pouvez nous transmettre votre facture à l'adresse email suivante : customersuccess@loxsolution.com",
    ],
    emailSent: {
      title: "Email envoyé!",
      sub_text:
        "Votre message est arrivé dans notre boîte de réception. L'un de nos Loxtranauts formés sera avec vous sous peu.",
    },
    claimCreated: {
      title: "Enquête créée avec succès",
      text: "Votre demande sera traitée dans les 24 heures. Vous pourrez consulter les mises à jour sur la page des détails de la enquête ou sur la page 'Mes enquêtes'.",
    },
    claimNotCreated: {
      title: "Création d'une revendication infructueuse",
      data: {
        text: "Les informations jointes à votre demande n'ont pas pu être téléchargées en raison d'un problème inattendu.",
      },
      documents: {
        text: "Les documents joints à votre demande n'ont pas pu être téléchargés en raison d'un problème inattendu.",
      },
    },
    emailError: {
      title: "Ah !",
      sub_text:
        "Quelque chose s'est mal passé dans le système de messagerie. Nous avons été contactés et nous y sommes.",
    },
    loading: {
      register: "Nous rassemblons vos données !",
    },
    loginErrors: {
      title: "Ah!",
      status_403:
        "Vos données sont en cours de traitement. Nous vous informerons lorsque vous pourrez avoir accès à votre compte.",
      status_401: "Identifiant ou mot de passe incorrect.",
    },
    companyExsists: {
      title: "Entreprise déjà inscrite",
      textBlock:
        "Nous avons constaté que l'entreprise que vous avez indiquée dispose déjà d'un administrateur. Veuillez lui demander de vous ajouter via la page des membres de l'équipe.",
    },
    carrierCredentials: {
      title: "Aucun titre à faire valoir",
      text: "Pour accéder à cette page et utiliser ses fonctionnalités, nous avons d'abord besoin de vos coordonnées de transporteur. Cela nous aidera à faire une enquête en votre nom.",
      confirmButton: "Aller à la page d'information sur les transporteurs",
    },
    lockedProducts: {
      title: "Nous travaillons sur ce produit!",
      text: "Si vous avez envie d'en savoir plus ou de l'utiliser rapidement, vous pouvez nous en parler dans la partie 'Retours utilisateurs'.",
    },
    lockedDashboard: {
      title: "Unfortunately you don't have access!",
      text: "Not to worry, if you would like further information on our Dashboarding product please get in contact with on of our amazing team members at customersuccess@loxsolution.com",
    },
    noData: {
      title: "Invalid Search",
      text: "The filters you entered gathered zero results. Please broaden your search in order to retreive the required results.",
    },
    noDataUploadError: {
      title: "Ah !",
      text: "Il semble que vous ayez téléchargé un fichier sans soumettre de données supplémentaires. Veuillez vous assurer que vous remplissez toutes les colonnes pour un numéro de suivi.",
    },
    profileUpdate: {
      title: "Good Job!",
      text: "You updated your profile.",
    },
    profileUpdateFailure: {
      title: "Oops!",
      text: "Your profile wasn't updated, due to technical issue. Please retry and if the problem presists please let us know in our feedback section.",
    },
    duplicateFile: {
      title: "Doublon détecté !",
      text: " a été upload plus d'une fois. Nous n'avons conserver qu'un seul document.",
    },
    verifyEmail: {
      subject: "Vérifiez votre adresse mail",
      title: "Bienvenue chex Lox !",
      addressed: "Bonjour",
      message:
        "Vous venez de créer un compte chez Lox. Pour finaliser votre inscription merci de suivre les instructions suivantes. Vous trouverez ci-dessous votre code de vérification, vous pouvez dès à présent le rentrer dans l'espace dédié à cet effet sur notre site.",
      verification: "Voici votre code de vérification ",
      finishing: "Bien à vous,",
      from: "L'équipe Lox",
      error: "Il semblerait que votre code soit incorrect, merci de réessayer.",
      success:
        "Code bon ! Merci d'enregistrer vos identifiants transporteurs ou télécharger vos factures.",
      emailExsists: [
        "Ah!",
        "Un utilisateur est déjà enregistré avec cette adresse email. Vérifiez votre email et si le problème persiste, contactez nous à l’adresse email customersuccess@loxsolution.com!",
      ],
      emailNotFound:
        "It seems the email address you have enterred is not present in our Database. Please carefully re-check and try again.",
    },
    api: {
      success: {
        title: "MERCI 🌟",
        text: "Notre équipe est actuellement en train de finaliser l'intégration. Vous recevrez un mail de confirmation d'ici quelques jours.",
      },
      failed: {
        text: "The config you sent us wasn't saved. Please try again and if the problem persists please send us a email at customersuccess@loxsolution.com"
      },
      disconnected: {
        title: "API déconnecté 🌟",
        text: "Nous n'effectuerons plus de demandes avec la configuration API indiquée. Veuillez simplement configurer à nouveau la configuration de l'API si vous souhaitez que nous continuions à effectuer des demandes."
      },
    },
    cancelApi: {
      cancelTitle: "Êtes-vous certain de vouloir arrêter l'intégration API ?",
      disconnectedTitle: "Êtes-vous sûr de vouloir déconnecter l'intégration API ?",
      text: "Pas de soucis ! Vous aurez toujours la possibilité de remettre en place l'API dans le future.",
    },
    manualUpload: {
      title: "Document partagé !",
      text: "Vos changements seront pris en compte sous 24 heures.",
    },
    uploadFailed: {
      title: "Ah !",
      text: "Le téléchargement du fichier a échoué. Veuillez réessayer et si le problème persiste, envoyez-nous un email à customersuccess@loxsolution.com"
    },
    templateFailed: "Le  modèle n'a pas pu être téléchargé, veuillez réessayer et si le problème persiste, contactez-nous par email à customersuccess@loxsolution.com",
    helpSetupAPI: {
      problem1: "Je n'arrive pas à configurer mon API",
      problem2: "Je ne comprends pas la documentation",
    },
    helpProcessAPI: {
      problem1: "Où en est l'intégration ?",
      problem2: "Je dois modifier la configuration de mon API",
    },
    helpConnected: {
      problem1: "Quels types de documents sont acceptés ?",
      problem2: "Puis-je avoir accès aux documents que j'ai déposés ?",
    },
    requestSent: "Demande d'aide envoyée",
    messageSent: "Notre équipe support a été informée de votre situation. Un membre de notre équipe vous contactera sous 48h pour résoudre le problème. Nous nous excusons pour la gêne occasionnée.",
    messageNotSent: "Le message n'a pas réussi à être transmis à notre équipe d'assistance. Veuillez réessayer et si le problème persiste, envoyez-nous un e-mail à customersuccess@loxsolution.com",
    notAdmin: {
      title: "Non-autorisé",
      text: "Vous avez besoin des droits administrateur pour avoir accès à cette page",
    },
    accessDenied: {
      title: "Non-autorisé",
      home: {
        text: "Vous devez accéder au produit de tableau de bord pour accéder à cette page.",
      },
      PasswordRequest: {
        text: "Vous devez avoir demandé une réinitialisation de votre mot de passe pour accéder à cette page."
      },
    },
    overwriteClaim: {
      title: "Enquête déjà Existante",
      text: "Si vous souhaitez remplacer la enquête précédente sur ce numéro de suivi, cliquez simplement oui, ou cliquez non pour garder l'ancienne version.",
    },
    successfulClaim: {
      text: "Notre équipe travaille actuellement au traitement de votre enquête, vous serez informé de l'avancement.",
    },
    wrongFileType: {
        title: "Type de Document Incorrect !",
        text: "Le fichier fourni n'est pas du bon type, veuillez vous assurer de télécharger le fichier dans les formats { types }.",
    },
    claimHistoryActionReceived:{
      title: "Action Reçue",
      text: "Elle sera visible sur la plateforme transporteur sous 24 heures.",
    },
  },
  feedback: {
    email: "Please make sure to fill in your email.",
    password: "Please make sure to fill in your password.",
    firstName: "Please make sure to fill in your First Name.",
    lastName: "Please make sure to fill in your Last Name.",
    company: "Please make sure to fill in your Company.",
    jobTitle: "Please make sure to fill in your Job Title.",
    phone: "Please make sure to fill in your Phone Number.",
    noData: "No data found matching your input.",
    required: {
      inputs: "Ce champ est obligatoire",
      email: "Adresse email invalide",
      document: "Le document est nécessaire pour la création de la demande."
    },
    invalidAmountInclusive: "Le montant total ne peux être inférieur à celui hors taxe",
    invalidDate: "Plage de dates non valide : Veuillez saisir une date comprise entre deux ans et hier.",
  },
  processed: {
    awaiting: {
      title: "Bienvenue chez Lox !",
      explanation:
        "Vos données sont toujours en cours de traitement. Nous vous informerons une fois celles-ci disponibles sur la plateforme. Cela prend généralement quelques jours.",
    },
    noUploads: {
      title: "Bienvenue chez Lox !",
      explanation:
        "Nous avons remarqué que vous n'avez pas téléchargé d'identifiants de transporteur ou de factures. Cela signifie que vous ne serez pas en mesure de visualiser les données sous le nom de votre entreprise. Veuillez nous contacter via le bouton \"Besoin d'aide\" ci-dessous si vous rencontrez des problèmes.",
    },
    concerns: {
      title: "Besoin d'aide ?",
    },
  },
  graphDescriptions: {
    home: "( Montant facturé par transporteur )",
    refunds: "( Montant crédits par transporteurs )",
  },
  generatedInvoice: {
    invoice: "Facture",
    subTotal: "sous-total",
    address: "Adresse",
    zipCode: "Zip Code",
    country: "Country",
    invoiceNumber: "Numéro de facture",
    shippingAddress: "Adresse de livraison",
    orderDetails: "Détails de la commande",
    orderDate: "Date de commande",
    billingAddress: "Address de facturation",
    vat: "TVA",
    clientName: "Nom du client",
    clientStreet: "Rue du client",
    clientPostalCode: "67890",
    clientCity: "Ville du client",
    clientCountry: "Pays du client",
    description: "description",
    quantity: "quantité",
    unitPrice: "prix unitaire",
    totalPrice: "prix total",
    companyInformation: "Information de l'entreprise",
    clientInformation: "Détails du client",
    clientName: "Nom du client",
    productDetails: "Détails des achats",
    companyAddress: "Adresse de l'entreprise",
  },
};
